export type RegionDto = {
  code: string;
  display: string;
};

export class Regions {
  private isInternational: boolean = false;
  private countries: string[] = [];
  private states: string[] = [];

  constructor(isInternational: boolean, regions: string[]) {
    this.isInternational = isInternational ?? false;

    if (isInternational) {
      this.countries = regions;
    } else {
      this.states = regions;
    }
  }
}
