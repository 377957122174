import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import TenantForm from "./TenantForm";
import { useTenantDetailQuery } from "../../../generated/urql-graphql";

const TenantDetails: FC = (): ReturnType<FC> => {
  const { tenantParam } = useParams<{ tenantParam: string }>();
  const isAddForm = tenantParam?.length !== 36;
  const tenantId = !isAddForm ? tenantParam : "10000000-0000-0000-0000-000000000000";
  const pageTitle = isAddForm ? "Add Tenant" : "Edit Tenant"
  const [tenantQuery] = useTenantDetailQuery({
    variables: {tenantId }
  });
  if (tenantQuery.fetching) {
    return <Loader />
  }

  const error = tenantQuery.error;
  if (error) {
    return <PageError error={{ source: "TenantDetails", errMsg: error.message }} />;
  }

  const tenantData = tenantQuery.data?.operator_by_pk;
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="col-sm-12 col-xl-8">
          <ReturnHeader title={pageTitle} url="/tenants" />
          <TenantForm tenantData={tenantData!} />
        </div>
      </div>
    </>
  )
}

export default TenantDetails