import { useRef, useState } from "react";
import {
  AttributeMappingPartFragment,
  Imports_Attribute_Mapping_Insert_Input,
  Imports_Category_Mapping_Insert_Input,
  useCategoryMappingQuery,
  useCreateProductImportMutation,
  useCtgMappingSubscription,
  useRemoveAttrMappingMutation,
  useRemoveCtgMappingMutation,
  useUpsertAttrMappingMutation,
  useUpsertCtgMappingMutation
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Error403 } from "../../common/components/Errors/Error403";
import { IStep, MultipleSteps, StepsRef } from "../../common/components/MultipleSteps";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useGblAttrList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadImportFile } from "../../common/miscellaneous/storage";
import { delay, emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { IMappingResults, MappingContext, getDefMapResults } from "../utils/types";
import { MapAttribute } from "./MapAttribute";
import { MapCategory } from "./MapCategory";
import { ProcessFile } from "./ProcessFile";
import { ProcessImport } from "./ProcessImport";
import { Review } from "./Review";

interface IRemove {
  key: string;
  id: string;
}

interface IProps {
  mappedAttrs: AttributeMappingPartFragment[];
}

export const ImportProducts = (props: IProps) => {
  const { mappedAttrs } = props;
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const { tenant_url_tag: tenantUrlTag, setup } = context.operatorInfo;
  const baseRoute = `/${tenantUrlTag}/products`;
  const [goNext, setGoNext] = useState<boolean>(false);
  const [ctgQueried] = useCategoryMappingQuery();
  const [ctgSubed] = useCtgMappingSubscription();
  const [, createImportMutation] = useCreateProductImportMutation();
  const [, upsertAttrMutation] = useUpsertAttrMappingMutation();
  const [, removeAttrMutation] = useRemoveAttrMappingMutation();
  const [, upsertCtgMutation] = useUpsertCtgMappingMutation();
  const [, removeCtgMutation] = useRemoveCtgMappingMutation();
  useGblAttrList();

  const resultsRef = useRef<IMappingResults>(getDefMapResults());
  const stepsRef = useRef<StepsRef | null>(null);

  if (setup.is_pim && !userInfo.seller_id)
    return <Error403 />;

  const error = ctgSubed?.error;
  if (error) {
    return <PageError error={{ source: "ProductsImportRoute", errMsg: error.message }} />;
  }

  const mappedCtgs = ctgSubed?.data?.category_mapping || ctgQueried?.data?.category_mapping;

  const syncMapping = (mapHash: IHash, removes: IRemove[]) =>
    removes.map(rmv => rmv.key)
      .forEach(key => mapHash[key] = { ...mapHash[key], id: "" });

  const saveAttrMapping = async (saveOnly: boolean = false) => {
    const { attrMapping, importing } = resultsRef!.current;
    const updating = importing.type === "update";
    const removes = Object.values(attrMapping)
      .filter(atm => !atm.attribute_id && atm.id)
      ?.map(atm => ({ key: atm.sheet_column, id: atm.id }));
    const removeIds = removes.map(rmv => rmv.id);
    resultsRef!.current.importing.changeType = false;

    const newMapped: Imports_Attribute_Mapping_Insert_Input[] = [];
    Object.values(attrMapping).filter(atm => (updating ? !atm.required : true) && atm.attribute_id
      && (!atm.id || atm.orig_attr_id !== atm.attribute_id))
      .forEach(atm => {
        const temp = {
          operator_id: userInfo.operator_id,
          seller_id: userInfo.seller_id || emptyUuid,
          sheet_column: atm.sheet_column,
          attribute_id: atm.attribute_id
        };
        newMapped.push(atm.id ? { ...temp, id: atm.id } : temp);
        mappedAttrs?.filter(mAttr => mAttr.attribute_id == atm.attribute_id)
          .forEach(mAttr => removeIds.push(mAttr.id));
      });

    if (!newMapped.length && !removeIds.length)
      return true;

    if (removeIds.length) {
      await removeAttrMutation({ removeIds },
        {
          additionalTypenames: ["imports.attribute_mapping"],
        }
      );
      syncMapping(attrMapping, removes);
    }

    if (newMapped.length) {
      removeIds.length && await delay(1000);
      const res = await upsertAttrMutation({ newMapped },
        {
          additionalTypenames: ["imports.attribute_mapping"],
        }
      );
      alertsRef.current?.generate(mutationInfo("product attribute mapping", MutationAction.Update, res));
    }
    return true;
  }

  const saveCtgMapping = async (saveOnly: boolean) => {
    const { ctgMapping, importing } = resultsRef!.current;
    const updating = importing.type === "update";
    const ctgs = Object.values(ctgMapping).filter(ctg => ctg.ctg_fullname);
    if (!ctgs.length) {
      !saveOnly && !updating && alertsRef.current?.add("At lease one category mapping required", "error");
      return updating;
    }
    const removes = Object.values(ctgMapping)
      .filter(ctg => !ctg.category_id && ctg.id)
      ?.map(ctg => ({ key: ctg.sheet_category_value, id: ctg.id }));
    const removeIds = removes.map(rmv => rmv.id);

    const newMapped: Imports_Category_Mapping_Insert_Input[] = [];
    Object.values(ctgMapping).filter(cgm => cgm.category_id
      && (!cgm.id || cgm.orig_ctg_id !== cgm.category_id))
      .forEach(cgm => {
        const temp = {
          operator_id: userInfo.operator_id,
          seller_id: userInfo.seller_id || emptyUuid,
          sheet_category_value: cgm.sheet_category_value,
          category_id: cgm.category_id
        };
        newMapped.push(cgm.id ? { ...temp, id: cgm.id } : temp);
      });

    if (!newMapped.length && !removeIds.length)
      return true;

    if (removeIds.length) {
      await removeCtgMutation({ removeIds },
        {
          additionalTypenames: ["imports.category_mapping"],
        }
      );
      syncMapping(ctgMapping, removes);
    }
    if (newMapped.length) {
      removeIds.length && await delay(1000);
      const res = await upsertCtgMutation(
        { newMapped },
        {
          additionalTypenames: ["imports.category_mapping"],
        }
      );
      alertsRef.current?.generate(mutationInfo("product category mapping", MutationAction.Update, res));
    }
    return true;
  }

  const storeFile = async (saveOnly: boolean = false) => {
    const results = resultsRef.current;
    const url = await uploadImportFile(results.sheet.file);
    if (url) {
      const importInfo = {
        seller_id: userInfo.seller_id,
        filename: results.sheet.file?.name,
        filepath_storage: url,
        sheet_name: results.sheet.sheetName,
        user_id: userInfo.user_id,
        operator_id: userInfo.operator_id,
        action: results.importing.type
      };
      const res = await createImportMutation({ importInfo });
      alertsRef.current?.generate(mutationInfo("product import info", MutationAction.Create, res));
      if (res?.data) {
        results.importing.id = res?.data?.prodImport?.id;
        return true;
      }
    }
    return false;
  }

  const steps: IStep[] = [
    { label: "1. Choose a file", formId: "global_mapping_form" },
    { label: "2. Attribute Mapping", save: saveAttrMapping },
    { label: "3. Category Mapping", save: (saveOnly: boolean) => { return saveCtgMapping(saveOnly); }, autoSave: true },
    { label: "4. Review", save: storeFile },
    { label: "5. Process", hidePrevBtn: true },
  ];
  return (
    <>
      <div className="product-import-wizard">
        <ReturnHeader
          url={baseRoute}
          title="Import Products"
          description="This wizard will guide you through a step-by-step process to upload and map the fields from your product import file to the fields in MarketPush."
        />
      </div>
      <MappingContext.Provider
        value={{
          attrMapped: mappedAttrs || [],
          ctgMapped: mappedCtgs || [],
          resultsRef,
        }}
      >
        <MultipleSteps ref={stepsRef} steps={steps} goNext={goNext}>
          <ProcessFile next={() => stepsRef.current?.navNext()} setGoNext={setGoNext} />
          <MapAttribute />
          <MapCategory />
          <Review />
          <ProcessImport />
        </MultipleSteps>
      </MappingContext.Provider>
    </>
  );
};
