import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  Reason_Category_Enum,
  useCloseIncidentMutation,
  useIncrementOfferStockQuantityMutation,
  useSetOfferStockQuantityMutation,
  useUpdateItemsStatusMutation
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { useReasonList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { footerText } from "./OrderItems";

interface IProps {
  show: boolean;
  action: string;
  itemIds: string[] | null;
  setClose: () => void;
}

interface DiglogInfoItem {
  text: string | JSX.Element;
  reason_category: Reason_Category_Enum | null;
  item_status?: string
}

export const ReasonDialog: FC<IProps> = ({ show, action, itemIds, setClose }) => {
  const updateStatusMutation = useUpdateItemsStatusMutation()[1];
  const incrementOfferStockQuantityMutation = useIncrementOfferStockQuantityMutation()[1];
  const setOfferStockQuantityMutation = useSetOfferStockQuantityMutation()[1];
  const closeIncidentMutation = useCloseIncidentMutation()[1];
  const reasons = useReasonList();

  const userInfo = useUserInfo();

  const dialogInfo: { [key: string]: DiglogInfoItem } = {
    cancel_item: {
      text: <p>Are you sure you want to cancel this item? This action cannot be undone.</p>,
      reason_category: Reason_Category_Enum.CancelOrder,
      item_status: "Cancelled"
    },
    cancel_order: {
      text: <p>Are you sure you want to cancel this order? This action cannot be undone.</p>,
      reason_category: Reason_Category_Enum.CancelOrder,
      item_status: "Cancelled"
    },
    reject_item: {
      text: <p>Are you sure you want to reject this item? This action cannot be undone.</p>,
      reason_category: Reason_Category_Enum.RejectOrder,
      item_status: "Rejected"
    },
    reject_order: {
      text: <p>Are you sure you want to reject this order? This action cannot be undone.</p>,
      reason_category: Reason_Category_Enum.RejectOrder,
      item_status: "Rejected"
    },
    hold_order: {
      text: <p>Are you sure you want to place this order on hold?</p>,
      reason_category: Reason_Category_Enum.RejectOrder
    },
    close_incident: {
      text: <p>Are you sure you want to close incident on this item? This action cannot be undone.</p>,
      reason_category: Reason_Category_Enum.CloseIncident
    }
  };

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (formData: FieldValues) => {
    const itemStatus = dialogInfo[action].item_status;
    if (itemStatus) {
      await updateStatus(itemStatus, formData.reason);
    }
    else if (action === "close_incident") {
      const res = await closeIncidentMutation({
        incident: {
          status: "Closed",
          close_date: new Date().toISOString(),
          close_reason: formData.reason
        },
        itemId: itemIds?.find(item => item)
      });
      if (res && !res.error) {
        setClose();
      }
      alertsRef.current?.generate(mutationInfo("status", MutationAction.Update, res));
    }
  };

  const updateStatus = async (status: string, reason: string | null) => {
    const res = await updateStatusMutation({
      updateIds: itemIds,
      itemStatus: status,
      reason: reason
    });
    if (res && !res.error && (status === "Cancelled" || status === "Rejected")) {
      const allPromiseOffer: any = res?.data?.update_order_item?.returning.map(async (updatedOrderItem) => {
        let resOffer;
        if (reason === "out_of_stock" || reason === "insufficient_stock") {
          resOffer = await setOfferStockQuantityMutation({
            offerId: updatedOrderItem.offer_id,
            operatorId: userInfo.operator_id,
            stockQuantity: 0
          })
        } else {
          resOffer = await incrementOfferStockQuantityMutation({
            offerId: updatedOrderItem.offer_id,
            operatorId: userInfo.operator_id,
            incQty: updatedOrderItem.quantity
          })
        }
        if (resOffer && !resOffer.error) {
          return Promise.resolve(resOffer.data)
        } else {
          return Promise.reject(resOffer.error)
        }
      })
      Promise.all(allPromiseOffer).then(resOffer => {
        alertsRef.current?.generate(mutationInfo("status", MutationAction.Update, res));
        setClose();
      }).catch(error => {
        alertsRef.current?.generate(mutationInfo("status", MutationAction.Update, error));
      })
    } else {
      if (res && !res.error) {
        setClose();
      }
      alertsRef.current?.generate(mutationInfo("status", MutationAction.Update, res));
    }
  }

  const continueConfirm = async (isContinue: boolean) => {
    if (!isContinue) {
      setClose();
    }
  };

  const reasonOptions = dialogInfo[action]?.reason_category ? reasons.filter(
    (reason) => reason.category === dialogInfo[action].reason_category
  )
    .map((reason) => {
      return { value: reason.value, label: reason.label };
    }) : [];

  return (
    <>
      {dialogInfo[action] && <Dialog
        show={show}
        subFormId={`order-action-form-${action}`}
        title={action.replace("_", " ")}
        continueText="Confirm"
        staticModal={true}
        continue={continueConfirm}
        size="lg"
        footerText={footerText}
        contineBtnCss="btn-danger"
      >
        {dialogInfo[action].text}
        <FormProvider {...methods}>
          <Form
            data-testid=""
            noValidate
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
            id={`order-action-form-${action}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormSelect
              name="reason"
              label="Select reason"
              options={reasonOptions}
              css="w-50"
              reg_options={{ required: false }}
            />
          </Form>
        </FormProvider>
      </Dialog>}
    </>
  );
}