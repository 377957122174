import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Vw_Offer_Order_By,
  useDeleteOfferMutation,
  useOfferListAggQuery,
  useOfferListQuery,
  useUpdateOfferStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { maxCountLimitLargeData } from "../../common/miscellaneous/data";
import { badgeCss, mutationInfo } from "../../common/miscellaneous/utility";
import {
  ELoadingType,
  IActionState,
  IDateRange, MutationAction, OrderByType
} from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { getProdImgUrls } from "../../products-route/types/product";
import { handleTitle } from "../../sellers-route/handlers/tableHandlers";

interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}

export interface IOfferList {
  numOfOffers: number;
  sellerId: string | null | undefined;
  handleCallBack?: (where: string, orderBy: Vw_Offer_Order_By | Vw_Offer_Order_By[]) => void;
}

export const createOrderByFunc = (by: string, orderBy: OrderByType) => {
  return { [orderBy.column_name]: orderBy.orderBy };
};
export const ActiveOffers = ({ numOfOffers, sellerId, handleCallBack }: IOfferList) => {
  const [context] = useMainContext();
  const tenant_profit_model = context.operatorInfo.setup.profit_model;
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  const [loading, setLoading] = useState<number>(-1);
  const { number_items_per_page } = context.operatorSettings.preset;
  const [offset, setOffset] = useState<number>(0);
  const [filterCount, setFilterCount] = useState<number>(numOfOffers);
  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [selectedFilters, setSelectedFilters] = useState<any>("");
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const orderByOptions: { [key: string]: string } = {
    seller_item_sku: "seller_product_sku" +  (numOfOffers > 25000 ? "_lower" : ""),
    seller: "company_name",    // utilize company_name instead since testing show not using lower index 
    product: "product_title" + (numOfOffers > 25000 ? "_lower" : ""),
    location: "address_name" + (numOfOffers > 25000 ? "_lower" : ""),
    quantity: "stock_quantity",
    total_qty_ordered: "order_count",
    id: "id",
    status: "offer_avail_for_sale_status",
    currency: "currency_code",
  };

  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "seller item sku",
    column_name: orderByOptions.seller_item_sku,
    orderBy: "asc",
  });

  const formatStatus = (st: string) => {
    if (st === "OutOfStock") return "Out Of Stock";
    else return st;
  };
  const userInfo = useUserInfo();

  const tableColumnToSearch = ["seller_product_sku_lower"];

  const customRule = `,"operator_id":{"_eq": "${context.operatorInfo.id}")}, 
    "offer_avail_for_sale_status":{"_in": ["Active", "Inactive","OutOfStock"]}` + 
    !sellerId ? "" : `,"seller_id":{"_eq": "${sellerId}"}`;
  const handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnToSearch, searchValue.toLowerCase(), true),
    dateRange,
    selectedFilters,
    customRule: customRule,
  });

  const orderByString = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }]
    : [{ [orderByOptions.seller_item_sku]: "asc" }];

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };
  const [queried] = useOfferListQuery(inputs);
  const [queriedCount, executeQuery] = useOfferListAggQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      count_limit: maxCountLimitLargeData,
      where: JSON.parse(handleWhereStr),
    },
  });
  const [, deleteMutation] = useDeleteOfferMutation();
  const [, archiveMutation] = useUpdateOfferStatusMutation();

  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const offerRoute = `/${tenantUrlTag}/offers`;
  const sellerRoute = `/${tenantUrlTag}/sellers`
  const accessOffer = userInfo.permissions! & context.permissions.access_offer;
  const manageOffer = userInfo.permissions! & context.permissions.manage_offer;
  const deleteOffer = userInfo.permissions! & context.permissions.delete_offer;
  const isSeller = !!sellerId;
  const navigate = useNavigate();
  const multiSelectRef = useRef<IRef | null>(null);
  const [actionState, setActionState] = useState<IActionState>({ ids: null, action: "Archive" });

  const columnNames = isSeller
    ? [
      "seller item sku",
      //"name",
      "product",
      "location",
      "status",
      "quantity",
      "price range",
      "currency",
      "total qty ordered",
      "actions",
    ]
    : [
      "seller item sku",
      "product",
      "seller",
      //"name",
      // "location",
      "status",
      "quantity",
      "price range",
      "currency",
      "total qty ordered",
    ];

  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
      handleCallBack && handleCallBack(handleWhereStr, orderByString);
    }
  }, [queried.data]);

  useEffect(() => {
    if (searchValue !== '' || selectedFilters !== '' || dateRange.startDate || dateRange.endDay) {
      executeQuery({ requestPolicy: 'cache-and-network' });
    }
    else {
      setFilterCount(numOfOffers);
    }
  }, [searchValue, selectedFilters, dateRange]);

  useEffect(() => {
    const num = queriedCount.data?.vw_offer_aggregate?.aggregate?.count || numOfOffers;
    setFilterCount(num);
  }, [queriedCount.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ActiveOffers", errMsg: error.message }} />;
  }

  const errorCount = queriedCount.error;
  if (errorCount) {
    return <PageError error={{ source: "ActiveOffers", errMsg: errorCount.message }} />;
  }

  if (!queried.data) {
    return (
      <>
        <Card>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={columnNames} numberOfRows={number_items_per_page} />
        </Card>
      </>
    );
  }
  const offers = queried?.data?.vw_offer;

  const sellerStatus = (seller_status: string) => {
    if (seller_status === 'Closed') {
      return (
        <span className="ms-2 tooltip-custom top">
          <span className="tooltip-text">Seller is closed
          </span>
          <i className="bi bi-exclamation-circle text-dark"></i>
        </span>);
    }
    if (seller_status === 'Suspended') {
      return (
        <span className="ms-2 tooltip-custom top">
          <span className="tooltip-text">Seller is suspended
          </span>
          <i className="bi bi-exclamation-circle text-danger"></i>
        </span>);
    }
    return <></>;
  }
  const tableData = offers.map(offer => {
    const actions = [
      {
        enabled: (isSeller && !manageOffer) || (!isSeller && accessOffer),
        actionType: "view",
        id: offer.id,
        display: "View",
        icon: "bi bi-eye",
        actionFunc: (id: string) => navigate(`${offerRoute}/${id}`),
      },
      {
        enabled: isSeller && manageOffer,
        actionType: "edit",
        id: offer.id,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: (id: string) => navigate(`${offerRoute}/${id}`),
      },
      {
        enabled: isSeller && deleteOffer,
        actionType: "archive",
        id: offer.id,
        display: "Archive",
        icon: "bi bi-archive",
        actionFunc: (id: string) => handleAction(id, "Archive"),
      },
      {
        enabled: offer.order_count === 0 && isSeller && deleteOffer,
        actionType: "delete",
        id: offer.id,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: (id: string) => handleAction(id, "Delete"),
      },
    ];
    const productImgUrls = getProdImgUrls(offer.product_image_urls);
    return {
      id: offer.id,
      description: offer.description,
      seller: isSeller ? <NavLink
        data-role="nav"
        data-testid=""
        to={`${sellerRoute}/${offer.seller_id}`}
        className="text-decoration-underline">
        {offer.company_name}
      </NavLink> : (
        <>
          <NavLink
            data-role="nav"
            data-testid=""
            to={`${sellerRoute}/${offer.seller_id}`}
            className="text-decoration-underline">
            {offer.company_name}
          </NavLink>
          {sellerStatus(offer.seller_status ?? "")}

          <div className="location">
            <div className="text-truncated text-capitalize mb-0">
              <div className="text-muted small font-weight-semi-bold">Location:</div>
              <div className="small">{handleTitle(offer.address_name || "", 15)} <div className="text-muted small">({handleTitle(offer.city || "", 15)}, {handleTitle(offer.state || "", 15)})</div></div>
            </div>
          </div>
        </>
      ),
      // Hold off on adding location column
      //name: offer.address?.name,
      // using Store Item SKU instead of seller_product_sku to fix Stores table columns.
      seller_item_sku: (
        <NavLink data-role="nav" data-testid="" to={`${offerRoute}/${offer.id}`} className="text-decoration-underline">
          {offer.seller_product_sku}
        </NavLink>
      ),
      quantity: offer.stock_quantity,
      price_range: (tenant_profit_model === "Markup") ?
        <div>
          <p className="mb-0">
            {offer?.min_price.toFixed(max_decimals_in_unit_price)
              + (offer?.min_price === offer?.max_price ?
                "" : " ~ " + offer?.max_price.toFixed(max_decimals_in_unit_price))}
          </p>
          <p className="mb-0">(Base: {
            offer?.min_origin_price?.toFixed(max_decimals_in_unit_price)
            + (offer?.min_origin_price === offer?.max_origin_price ?
              "" : " ~ " + offer?.max_origin_price?.toFixed(max_decimals_in_unit_price))
          })</p>
        </div>
        :
        offer.min_price.toFixed(max_decimals_in_unit_price) +
        (offer.min_price === offer.max_price ? "" : " ~ " + offer.max_price.toFixed(max_decimals_in_unit_price)),
      currency: offer.currency_code,
      product: (
        <div
          className="product-information cursor-pointer"
          onClick={() => navigate(`/${tenantUrlTag}/products/${offer.productship_id}`)}
        >
          <div className="d-flex flex-lg-column flex-xl-row">
            {productImgUrls?.length ? (
              <div className="product-image me-2 mt-2 d-none d-lg-block">
                <img
                  src={productImgUrls[0]}
                  data-url={offer.product_image_urls!}
                  alt={offer.product_title!}
                  width="60"
                  className="me-2"
                />
              </div>
            ) : (
              <div className="img-placeholder d-flex justify-content-center align-items-center mt-1 me-2 d-none d-lg-block">
                <i className="bi bi-card-image"></i>
              </div>
            )}
            <div className="product-details">
              <div className="product-header">
                <p className="product-title font-weight-semi-bold text-truncate mb-0">{offer.product_title}</p>
              </div>
              <p className="product-description small text-muted mb-0 d-none d-xl-block text-truncate">
                {offer.product_description}
              </p>
              {/*               <p className="product-mpin mb-0">
                <span className="text-muted small font-weight-semi-bold mb-0 me-1">MPIN:</span>
                <span className="small">{offer.product_mpin}</span>
              </p> */}
              <p className="product-extra-info mb-0 text-truncate">
                <span className="text-muted small font-weight-semi-bold mb-0 me-1">Manufacturer Name:</span>
                <span>{offer.manufacturer}</span>
              </p>
              <p className="product-extra-info mb-0 text-truncate">
                <span className="text-muted small font-weight-semi-bold mb-0 me-1">Mfr Part Number:</span>
                <span>{offer.mfr_part_number}</span>
              </p>
              <p className="offer-condition mb-0">
                <span className="text-muted small font-weight-semi-bold me-1">Condition:</span>
                <span className="small">{offer.condition}</span>
              </p>

              <p className="mb-0 line-height-sm">
                <small className="text-muted me-2">Lead Time to Ship:</small>
                <small className="text-uppercase">{offer.shipping_lead_time}  {offer.shipping_lead_time ?? 0 > 1 ? "Days" : "Day"}</small>
              </p>

            </div>
          </div>
        </div>
      ),
      location: (<div className="location">
        <div className="text-truncated text-capitalize mb-0">
          <div>{handleTitle(offer.address_name || "", 15)}</div>
          <div className="small text-muted"> {handleTitle(offer.city || "", 15)}, {handleTitle(offer.state || "", 15)}</div>
        </div>
      </div >),
      status: <span className={badgeCss(offer.status)}>{formatStatus(offer.status ?? "")}</span>,
      total_qty_ordered: offer.order_count,
      actions: <DropdownItems items={actions.filter((action) => action.enabled)} />,
    };
  });

  const options = ["Archive", "Delete"];

  const applyChanges = () => {
    const ids = multiSelectRef.current?.selectedData;
    const action = multiSelectRef.current?.selectedOption;
    if (ids && ids.length !== 0 && action) setActionState({ ids, action });
    return true;
  };
  const handleAction = (id: string, action: string) => {
    setActionState({ ids: [id], action });
  };
  const continueAction = async (isContinue: boolean) => {
    let action = MutationAction.Update
    if (isContinue) {
      let res;
      if (actionState.action === "Delete") {
        action = MutationAction.Delete
        res = await deleteMutation(
          { ids: actionState.ids },
          {
            additionalTypenames: [
              "offer_aggregate",
              "offer",
              "search_counts",
              "vw_offer",
              "search_offers",
            ],
          }
        );
      } else {
        action = MutationAction.Archive
        const archived = {
          at: new Date().toISOString(),
          by: userInfo.user_id,
        };
        res = await archiveMutation(
          { ids: actionState.ids, archived, offerStatus: "Archived" },
          {
            additionalTypenames: [
              "offer_aggregate",
              "offer",
              "search_counts",
              "vw_offer",
              "search_offers",
            ],
          }
        );
      }
      alertsRef.current?.generate(
        mutationInfo(
          "offer",
          action,
          res
        )
      );
    }
    reset();
  };
  const reset = () => {
    multiSelectRef.current?.clearSelectedData();
    setActionState({ ...actionState, ids: null });
  };

  const addFilterOptions = [
    { label: "Product title", value: "product_title_lower" },
    // { label: "Product MPIN", value: "product_mpin_lower" },
    { label: "Location", value: "address_name_lower" },
    {
      label: "Product Status",
      value: "product_status_lower",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Active', value: 'Active' },
          { label: 'Archived', value: 'Archived' },
        ]
    },
    { label: "Seller item sku", value: "seller_product_sku_lower" },
    { label: "Quantity", value: "stock_quantity", type: "numeric" },
    { label: "Product MPN", value: "mfr_part_number_lower" },
    { label: "Manufacturer", value: "manufacturer_lower" },
    { label: "Lead Time to Ship", value: "shipping_lead_time", type: "numeric" },
    {
      label: "Status", value: "offer_avail_for_sale_status",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
          { label: 'Out Of Stock', value: 'OutOfStock' },
        ]
    },
  ];

  if (!isSeller) {
    addFilterOptions.unshift({ label: "Seller", value: "company_name_lower" });
    addFilterOptions.push({
      label: "Seller Status",
      value: "seller_status_lower",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Open', value: 'Open' },
          { label: 'Archived', value: 'Archived' },
          { label: 'Pending', value: 'Pending' },
          { label: 'Closed', value: 'Closed' },
        ]
    });
  }
  return (
    <div className="col-12">
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={columnNames}
          data={tableData}
          options={options}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          ref={multiSelectRef}
          multiSelectTable={!!sellerId}
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          addFilterOptions={addFilterOptions}
          setSelectedFiltersFunc={setSelectedCountFilters}
          setSelectedFilters={setSelectedFilters}
          convertFiltersToLower={true}
          extraFilterOps={["contains"]}
          totalRecords={filterCount || 0}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Item Sku"
          searchHint={`Search by Item Sku`}
        />
      </Card>
      <Dialog
        show={!!actionState.ids}
        title={`${actionState.action} Offer`}
        continueText={actionState.action}
        continue={continueAction}
        contineBtnCss={"btn-danger"}
      >
        Are you sure you want to {actionState.action.toLowerCase()} this offer?
      </Dialog>
    </div>
  );
};
