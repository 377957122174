import { FC } from "react";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useSellerProductQuery,
  useUpdatePendingProductStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { getProdImgUrls } from "../types/product";
import { ProductDetails } from "./ProductDetails";
import { ProductImageCarousel } from "./ProductImageCarousel";

export const SellerProduct: FC = (): ReturnType<FC> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const state = searchParams.get('state') as null | { tab: string };
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const formatDate = useDateFormat();

  const { productId } = useParams<{ productId: string }>();
  const [queried] = useSellerProductQuery({
    requestPolicy: 'network-only',
    variables: { productId },
  });
  const [{ }, updatePendingProductStatusMutation] = useUpdatePendingProductStatusMutation();

  const userInfo = useUserInfo();
  const manageProduct = userInfo.permissions! & context.permissions.manage_product;
  const operatorManage = manageProduct && userInfo && userInfo.user_level! > 10;

  const navigate = useNavigate();

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "SellerProduct", errMsg: error.message }} />;
  }

  if (!queried.data || queried.fetching) {
    return <Loader />;
  }

  const productData = queried.data?.imports_vw_seller_product.find(prod => prod);
  const imgUrls = getProdImgUrls(productData?.data.product_image_urls).map(url => ({ url }));
  const approvedId = queried.data?.approved?.id;
  const baseUrl = `/${tenantUrlTag}/products`;

  const doStatusUpdate = async () => {
    const res = await updatePendingProductStatusMutation({
      productId,
      status: 'Approved',
    }, { additionalTypenames: ["imports.seller_products", "data_vw_product", "data_vw_product_aggregate"] });

    if (res.error && res.error.message.includes('unique_mfr_mpn_lowercase_idx')) {
      alertsRef.current?.generate({
        message: 'Product with this MPN and Manufacturer already exists',
        type: "error",
        errMsg: 'Product with this MPN and Manufacturer already exists',
      });
      return;
    }

    const info = mutationInfo("product", MutationAction.Update, res);
    alertsRef.current?.generate(info);

    if (info.type === "success") {
      navigate(`${baseUrl}/pending`)
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <ReturnHeader
            url={state ? `${baseUrl}/${state?.tab}` : `${baseUrl}`}
            title="Seller Product"
          >
            <>
              {approvedId &&
                (
                  <NavLink
                    data-role="nav"
                    data-testid="nav-message"
                    to={{ pathname: `${baseUrl}/${approvedId}` }}
                    state={{ tab: "pending" }}
                    className="nav-link text-truncate pb-0 pt-0"
                  >
                    Approved Product<i className="small bi bi-caret-right-fill"></i>
                  </NavLink>
                )}
              <div>{operatorManage && productData?.status === 'Pending' &&
                (
                  <Button
                    data-testid=""
                    className="btn btn-primary ms-1"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      doStatusUpdate();
                      event?.preventDefault();
                    }}>
                    Approve
                  </Button>
                )}
              </div>
            </>
          </ReturnHeader>
          <Card>
            <div className="row p-3">
              <div className="col-sm-12 col-md-3 d-flex justify-content-center">
                {imgUrls?.length ? (
                  <ProductImageCarousel images={imgUrls.map(img => img.url)} />
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-none d-md-block img-placeholder">
                      <i className="bi bi-card-image ps-2"></i>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-12 col-md-9">
                <h3 className="sub-title font-weight-semi-bold">
                  {productData?.product_title || "NAME"}
                </h3>
                <p className="mb-0">
                  <span className="font-weight-semi-bold">Status: </span>
                  <span className={`badge rounded-pill ${badgeCss(productData?.status)}`}>{productData?.status}</span>
                </p>
                <p className="mb-0">
                  <span className="font-weight-semi-bold">Category:</span>
                  <span className="text-muted ms-2">{productData?.product_category}</span>
                </p>
                <p className="mb-1">
                  <span className="font-weight-semi-bold">Published: </span>
                  <span className="text-muted">{formatDate(productData?.created_date)}</span>
                </p>
                <p className="mb-1">
                  <span className="font-weight-semi-bold">Source: </span>
                  <span className="text-muted">{productData?.seller_name}</span>
                </p>
              </div>
            </div>
            <div className="d-flex flex-row align-items-end justify-content-end mb-3">

            </div>
          </Card>
          <ProductDetails
            categoryId={productData?.category_id}
            productData={productData}
          />
        </div>
      </div>
    </>
  );
};
