
import { PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";
import { FC, createContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useFetchOrderDetailsSubscription,
  useOrderDetailsQuery,
  useUpdateItemsStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { useReasonList } from "../../common/hooks/globals";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, mutationInfo, separateWithSpace } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../handler/handler";
import { EditLineDialog } from "./EditLineDialog";
import OrderDetailsPdf from "./OrderDetailsPdf";
import { OrderHistory } from "./OrderHistory";
import {
  BillingInfo,
  CustomerInfo,
  OrderInfo,
  OrderItemBriefDescription,
  OrderTotal,
  SellerInfo,
} from "./OrderItems";
import OrderNotesComponent from "./OrderNotesComponent";
import OrderdetailActionButtons from "./OrderdetailActionButtons";
import { ReasonDialog } from "./ReasonDialog";
import { RefundDialog } from "./RefundDialog";
import RefundSummaryComponent from "./RefundSummaryComponent";
import ShippingInfoComponent from "./ShippingInfoComponent";

export const EditQuantityContext = createContext<
  [number, React.Dispatch<React.SetStateAction<number>>]
>([0, () => { }]);

export const OrderDetails: FC = (): ReturnType<FC> => {
  const { orderId } = useParams<{ orderId: string }>();
  const reasons = useReasonList();
  const userInfo = useUserInfo()!;
  const defState = { ids: null, action: "none", item: null };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const updateStatusMutation = useUpdateItemsStatusMutation()[1];

  const inputs = { variables: { orderId: orderId } };
  const [update, forceUpdate] = useState<number>(0);
  const totalAcceptedQuantity: { [key: string]: number } = {};
  const [context] = useMainContext();
  const tenant_profit_model = context.operatorInfo.setup.profit_model;
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag || "";
  const baseRoute = `/${tenantUrlTag}/orders`;

  const [queried] = useOrderDetailsQuery(inputs);
  const [subscribed] = useFetchOrderDetailsSubscription(inputs);

  const manageOrder = userInfo.permissions! & context.permissions.manage_order ? true : false;

  const stylePDF = StyleSheet.create({
    pdfViewerStyle: {
      width: '100%', height: '800px'
    }
  })

  const [offset, setOffset] = useState<number>(0);
  const formatDate = useDateFormat();

  const applyChanges = () => {
    return;
  };

  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "OrderDetails", errMsg: error.message }} />;
  }

  if (!queried.data && !subscribed.data) {
    return <Loader />;
  }

  const order = subscribed.data?.order_by_pk ?? queried.data?.order_by_pk;
  const { customer, seller, refunds, order_items, tracking_items, ...orderInfo } = order!;
  const sellerLocationsData = seller?.addresses || [];
  const locationOptions: SelectOption[] = sellerLocationsData?.map((loc) => {
    return { label: loc.name || "", value: loc.id };
  });

  const createColumnNames = (status: string) => {
    let columns = [
      "item",
      "product",
      "shipping from",
      "status",
      "quantity",
      "unit price",
      "subtotal",
    ];
    if (
      manageOrder &&
      (["Pending", "AwaitingFulfillment"].includes(status) || order?.cp_num_of_incident !== 0)
    ) {
      columns = [...columns, "actions"];
    }
    return columns;
  };

  const updateStatus = async (ids: string[]) => {
    const res = await updateStatusMutation({
      updateIds: ids,
      itemStatus: "Accepted",
      reason: null,
    });
    alertsRef.current?.generate(mutationInfo("status", MutationAction.Update, res));
  };

  const pendingItems: string[] =
    order_items.filter((item) => item.trg_status === "Pending").map((item) => item.id) ?? [];
  let currencySubTotal = 0;
  const data = order_items
    .sort((item1, item2) => item1.index - item2.index)
    .map((item) => {
      const {
        id,
        offer_id,
        index,
        product_info,
        cp_product_image,
        trg_status,
        quantity,
        unit_price,
        trg_price,
        reason,
        trg_incident_obj,
        cp_refund_qty,
        shipping_location_id,
        cp_base_price,
        unit_price_in_currency
      } = item;

      if (unit_price_in_currency
        && trg_status !== 'Rejected'
        && trg_status !== 'Cancelled') {
        currencySubTotal = currencySubTotal + unit_price_in_currency * quantity;
      }
      if (trg_status === "Accepted") totalAcceptedQuantity[id] = quantity;
      const currentLocationOption = locationOptions.find(
        (loc) => loc.value === shipping_location_id
      );
      const itemReason = trg_incident_obj?.reason || reason;

      const actions = [
        {
          enabled: ["Pending", "AwaitingFulfillment"].includes(trg_status!),
          actionType: "edit",
          id: id,
          display: "Edit",
          icon: "bi bi-pencil",
          actionFunc: () => setActionState({ ...actionState, action: "edit_item", item }),
        },
        {
          enabled: trg_status === "Pending",
          actionType: "accept",
          id: id,
          display: "Accept",
          icon: "bi bi-check-circle",
          actionFunc: () => updateStatus([id]),
        },
        {
          enabled: trg_status === "Pending",
          actionType: "reject",
          id: id,
          display: "Reject",
          icon: "bi bi-x-circle",
          actionFunc: () => setActionState({ ...actionState, action: "reject_item", item }),
        },
        {
          enabled: order?.trg_status === "AwaitingFulfillment" && trg_status === "Accepted",
          actionType: "cancel",
          id: id,
          display: "Cancel",
          icon: "bi bi-x-circle",
          actionFunc: () => setActionState({ ...actionState, action: "cancel_item", item }),
        },
        {
          enabled: trg_incident_obj !== null,
          actionType: "refund",
          id: id,
          display: "Issue Refund",
          icon: "bi bi-cash",
          actionFunc: () => setActionState({ ...actionState, action: "refund_item", item }),
        },
        {
          enabled: trg_incident_obj !== null && userInfo.user_level! >= 20,
          actionType: "close_incident",
          id: id,
          display: "Close Incident",
          icon: "bi bi-x-circle",
          actionFunc: () => setActionState({ ...actionState, action: "close_incident", item }),
        },
      ];
      const actionItems = actions.filter((action) => action.enabled);
      const purchaseCurrency = orderInfo.currency_info?.purchase_currency;
      return {
        id: id,
        item: `#${order?.order_number + "_" + index}`,
        product: (
          <>
            <OrderItemBriefDescription orderItem={item} />
          </>
        ),
        shipping_from: currentLocationOption?.label ?? "",
        status: (
          <>
            <div className={`badge rounded-pill ${badgeCss(trg_status)}`}>
              {separateWithSpace(trg_status ?? "")}
            </div>
            {itemReason && (
              <div className="display-7 text-mute mt-2">
                Reason: {reasons.find((opt) => opt.value === itemReason)?.label}
              </div>
            )}
          </>
        ),
        quantity: quantity,
        unit_price:
          <div>
            <div>{`$ ${addCommaFunc(unit_price, max_decimals_in_unit_price)}`}</div>
            {unit_price_in_currency && purchaseCurrency && 
              <small className="text-muted">({purchaseCurrency} {addCommaFunc(unit_price_in_currency, max_decimals_in_unit_price)})</small>}
          </div>,
        subtotal: (
          <div className="sub-total text-left" style={{ minWidth: "100px" }}>
            <div className={trg_price ? "" : "text-muted line-through"}>{`$ ${trg_price ? addCommaFunc(trg_price) : addCommaFunc(cp_base_price)}`}</div>
          </div>
        ),
        actions:
          actionItems.length === 0 || ["Rejected", "Canceled"].includes(item.trg_status!) ? null : (
            <DropdownItems items={actionItems} />
          ),
      };
    });

  const OrderInformation = (): ReturnType<FC> => {
    return (
      <div className="order-information">
        <div className="d-flex flex-wrap">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-3 order-xxl-1 order-lg-1 mb-3">
            <OrderInfo orderInfo={orderInfo} formatDate={formatDate} />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-3 order-xxl-2 order-lg-3 mb-3">
            <SellerInfo companyName={order?.seller?.company_name} sellerId={order?.seller_id} tenantUrlTag={tenantUrlTag} />
          </div>
          <div className="col-sm-12 col-lg-6 col-xxl-3 order-xxl-3 order-lg-4 mb-3">
            <CustomerInfo customer={order?.customer!} directShipping={order?.ship_direct_to_customer || false} />
          </div>
          <div className="col-sm-12 col-lg-6 col-xxl-3 order-xxl-4 order-lg-2 mb-3">
            <OrderTotal currencySubTotal={currencySubTotal} orderInfo={orderInfo} profitModel={tenant_profit_model} maxDecimalsInUnitPrice={max_decimals_in_unit_price} />
          </div>
        </div>
      </div>
    );
  };

  const orderForPdf = {
    order_items: order_items,
    status: order?.trg_status ?? '',
    payment_method: order?.payment_method,
    shipping_carrier: order?.shipping_carrier,
    shipping_method_code: order?.shipping_method_code,
    order_number: order?.order_number,
    refunds: order?.refunds,
    cp_commission: order?.cp_commission,
    cp_total_price: order?.cp_total_price,
    cp_shipping_refund: order?.cp_shipping_refund,
    shipping_price: order?.shipping_price,
    cp_items_refund: order?.cp_items_refund,
    cp_sub_total_price: order?.cp_sub_total_price,
    seller_id: order?.seller_id,
    po_number: order?.po_number,
    seller: order?.seller,
    shipped_date: formatDate(order?.shipped_date),
    trg_shipping_deadline: formatDate(order?.trg_shipping_deadline),
    shipping_deadline_time: formatDate(order?.trg_shipping_deadline, "time"),
    tax_info: order?.tax_info,
    currency_info: order?.currency_info,
    cp_base_sub_total_price: order?.cp_base_sub_total_price,
    cp_base_total_price: order?.cp_base_total_price
  }
  const trackingItems = tracking_items ?? [];

  return (
    <>
      <div className="col-lg-12">
        <ReturnHeader title="Orders" url={baseRoute}>
          <OrderdetailActionButtons
            orderData={orderInfo}
            pendingItems={pendingItems}
            orderItems={order_items}
          />
        </ReturnHeader>
        <div className="mb-3">
          <PDFDownloadLink
            document={<OrderDetailsPdf
              customer={customer!} order={orderForPdf}
              locationOptions={locationOptions}
              shippingAddr={customer?.shipping_address}
              reasons={reasons}
              trackingItems={trackingItems}
              maxDecimalsInUnitPrice={max_decimals_in_unit_price}
            />}
            fileName={`ORDER_${order?.order_number}.pdf`}
          >
            {({ blob, url, loading, error }) => (<Button data-testid="item-print" className="btn btn-flat p-0 me-4">
              <i className="bi bi-printer me-2" />
              Print
            </Button>)}
          </PDFDownloadLink>

          <Button
            data-testid="item-history"
            className="btn btn-flat p-0"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample">
            <i className="bi bi-clock-history me-2" />
            History
          </Button>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <OrderHistory></OrderHistory>
            <Card className="mb-4">
              <div className="border-bottom card-header bg-light-gray">
                <h4 className="d-flex card-title mb-0 justify-content-between align-items-center">
                  <span className="order-number">
                    <i className="bi bi-cart me-2"></i>
                    {`Order #${order?.order_number}`}
                  </span>
                  <span className={badgeCss(order?.trg_status)}>
                    {separateWithSpace(order?.trg_status)}
                  </span>
                </h4>
              </div>
              <div className="card-body">
                <OrderInformation />
                <hr className="dropdown-divider mb-3 bg-light-gray" />
                {order?.refunds?.length ? (
                  <RefundSummaryComponent
                    refunds={order?.refunds}
                    orderItems={order_items}
                  />
                ) : null}
                <div className="row">
                  <div className="col-md-12">
                    <Card className="mb-3">
                      <div className="border-bottom card-header">
                        <h5 className="d-flex card-title mb-0 align-items-center">
                          <i className="bi bi-bag me-2" />
                          <span>Product Details for Order #{order?.order_number}</span>
                        </h5>
                      </div>
                      <div className="card-body">
                        <Table
                          columnNames={createColumnNames(order?.trg_status ?? "")} //{columnNames}
                          data={data || []}
                          applyChanges={applyChanges}
                          offset={offset}
                          setOffset={setOffset}
                          totalRecords={0}
                        />
                      </div>
                    </Card>
                  </div>
                  <OrderNotesComponent orderId={orderId!} sellerId={order?.seller_id || ""} customerDetails={{ email: customer?.email, fullName: customer?.first_name + " " + customer?.last_name }} />
                  <>
                    <EditQuantityContext.Provider value={[update, forceUpdate]}>
                      <ShippingInfoComponent
                        customer={customer!}
                        orderInfo={orderInfo}
                        orderItems={order_items}
                        totalAcceptedQuantity={totalAcceptedQuantity}
                        trackingItems={tracking_items}
                      />
                    </EditQuantityContext.Provider>
                    <BillingInfo
                      firstName={customer?.first_name}
                      lastName={customer?.last_name}
                      billingAddress={customer?.billing_address}
                      paymentMethod={order?.payment_method}
                      debitDate={order?.debited_date}
                    />
                  </>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      {actionState.action === "edit_item" &&
        <EditLineDialog
          setClose={() => setActionState(defState)}
          locationOptions={locationOptions}
          itemData={actionState.item!}
        />}
      {actionState.action === "refund_item" &&
        <RefundDialog
          setClose={() => setActionState(defState)}
          itemData={actionState.item!}
        />}
      <ReasonDialog
        show={!["edit_item", "refund_item"].includes(actionState.action)}
        action={actionState.action}
        itemIds={[actionState.item?.id]}
        setClose={() => setActionState(defState)}
      />
    </>
  );
};
