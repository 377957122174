import { FC, useEffect, useRef, useState } from "react";

import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  ShippingZonePartFragment,
  useCreateAddShippingZoneMutation,
  useEditShippingZoneMutation
} from "../../../generated/urql-graphql";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction, SelectOption } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { RegionDto, Regions } from "../types/types";

type Props = {
  onClose: () => void;
  zone: ShippingZonePartFragment | null;
  usedStates: string[];
  usedCountries: string[];
  countries: RegionDto[];
  us_states: RegionDto[];
};

export const ShippingZoneForm: FC<Props> = (props): ReturnType<FC> => {
  const {
    onClose,
    zone,
    usedStates,
    usedCountries,
    countries,
    us_states
  } = props;
  const userInfo = useUserInfo();
  const [{ fetching: creating }, createMutation] = useCreateAddShippingZoneMutation();
  const [{ fetching: editing }, editMutation] = useEditShippingZoneMutation();
  const action = zone ? "Edit" : "Add";
  const [errorMsg, setErrorMsg] = useState<string>("");
  const selectRef = useRef<SelectRef | null>(null);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue
  } = methods;

  const status = watch("status") || false;
  const isInternational: boolean = watch("regions.isInternational", zone?.regions?.isInternational ?? false);
  const zoneName = watch("zone_name");

  const countriesOptions: SelectOption[] = countries.map((country) => ({
    label: country.display,
    value: country.code,
    disabled: usedCountries.includes(country.code)
  }));

  const usStatesOptions: SelectOption[] = us_states.map((state) => ({
    label: state.display,
    value: state.code,
    disabled: usedStates.includes(state.code)
  }));

  useEffect(() => {
    if (zone) {
      reset(zone);
    }
  }, [zone]);

  useEffect(() => {
    selectRef.current?.clear();
    if (isInternational)
      selectRef.current?.set(zone?.regions?.countries ?? []);
    else
      selectRef.current?.set(zone?.regions?.states ?? []);
  }, [isInternational]);

  useEffect(() => {
    if (!zone && zoneName)
      setValue("zone_key", zoneName.replace(/ /g, "-").toLocaleLowerCase());
  }, [zoneName]);

  const onSubmit = async (formData: FieldValues) => {
    const selected = selectRef.current?.value;
    const regions: Regions = new Regions(isInternational, selected);
    let res;
    if (zone) { // update
      res = await editMutation({
        id: zone.id,
        shpZone: { ...formData, updated_date: 'now()', regions: regions }
      });
    } else { // create
      const shpZone = {
        ...formData,
        operator_id: userInfo.operator_id,
        regions: regions
      } as any;
      res = await createMutation({ shpZone });
    }
    if (!res.error) {
      onClose();
      setErrorMsg("");
      mutationInfo("the shipping zone",
        zone ? MutationAction.Update : MutationAction.Create, res);
    }
    else {
      setErrorMsg(res.error.message);
    }
  }

  const continueAction = (isContinue: boolean) => {
    if (!isContinue)
      onClose();
  }

  return (
    <Dialog
      title={`${action} Shipping Zone`}
      continue={continueAction}
      continueText="Save"
      errorMsg={errorMsg}
      fetching={creating || editing}
      subFormId="manage_shipping_zone_form"
      footerText={
        <FormInput
          form_methods={methods}
          label={`Status: ${status ? "Enabled" : "Disabled"}`}
          name="status"
          type="checkbox"
          isswitch={true}
        />
      }
    >
      <FormProvider {...methods}>
        <Form
          data-testid=""
          id="manage_shipping_zone_form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="form-group row mb-3">
            <div className="col-6">
              <FormInput
                name="zone_name"
                label="Label"
                reg_options={{ required: true, maxLength: 80 }}
                placeholder="Please enter shipping zone"
                css="mb-0"
              />
              <span className="small text-muted">Your sellers will see this</span>
            </div>
            <div className="col-6">
              <FormInput
                name="zone_key"
                label="Name"
                readOnly={!!zone}
              />
            </div>
          </div>
          <FormTextarea
            name="description"
            label="Description"
            reg_options={{ maxLength: 300 }}
          />

          <FormInput
            form_methods={methods}
            label="International"
            name="regions.isInternational"
            type="checkbox"
            isswitch={true}
          />

          <div className="form-group row mb-3">
            <CustomSelect
              ref={selectRef}
              options={isInternational ? countriesOptions : usStatesOptions}
              placeholder={isInternational ? "Select Countries" : "Select States"}
              label={isInternational ? "Countries" : "US States"}
              disabled={false}
              multiple={true}
              required={true}
            />
          </div>

        </Form>
      </FormProvider>
    </Dialog>
  );
};
