import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  OpInfoPartFragment,
  useGetCurrencyQuery,
  useGetHubIndustryQuery,
  useUpsertTenantMutation
} from "../../../generated/urql-graphql";
import { Address } from "../../common/components/Address";
import { Card } from "../../common/components/Card";
import { Contact } from "../../common/components/Contact";
import { appRef } from "../../common/components/appStatus";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";

type Props = {
  tenantData: OpInfoPartFragment
}

const environmentTypes = [{ label: "Production", value: "Production" },
{ label: "Test", value: "Test" },
{ label: "Development", value: "Development" }];


const TenantForm: FC<Props> = ({ tenantData }): ReturnType<FC> => {
  const isAddForm = !tenantData;
  const navigate = useNavigate();
  const baseRoute = "/tenants";
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<{ label: string, value: string }[]>([]);
  const [industryTemplates, setIndustryTemplates] = useState<{ label: string, value: string }[]>([
    { label: "None", value: "" },
  ]);
  const profitModels = [
    { label: "Commission", value: "Commission" },
    { label: "Markup", value: "Markup" }];

  const [hubIndustryQueried] = useGetHubIndustryQuery();
  const [{ fetching }, upsertMutation] = useUpsertTenantMutation();
  const [currenciesQueried] = useGetCurrencyQuery();

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { ...tenantData },
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    setValue
  } = methods;

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (hubIndustryQueried.data) {
      let itemTemplates: SelectOption[] = [{ label: "None", value: "" }];
      hubIndustryQueried.data.pim_hub_industry.forEach((item) => {
        const itemTemplate: SelectOption = { label: item.name, value: item.pim_id };
        itemTemplates.push(itemTemplate);
      });
      setIndustryTemplates(itemTemplates);
    }

  }, [hubIndustryQueried.data]);


  useEffect(() => {
    if (currenciesQueried.data) {
      let currencyOptions: SelectOption[] = [];
      currenciesQueried.data.utility_currency.forEach((item) => {
        const currencyOption: SelectOption = { label: item.display, value: item.code };
        currencyOptions.push(currencyOption);
      });

      SortCurrenciesAndPutUsdAtTheTop(currencyOptions);
      setCurrencies(currencyOptions);

      const usdCurrency = currencyOptions.find((option) => option.value === "USD");
      const defaultCurrency = tenantData?.setup?.currency ?? usdCurrency?.value ?? "";

      setValue("setup.currency", defaultCurrency);
    }

    function SortCurrenciesAndPutUsdAtTheTop(currencyOptions: SelectOption[]) {
      currencyOptions.sort((a, b) => a.label.localeCompare(b.label));
      const usdIndex = currencyOptions.findIndex(i => i.value === "USD");
      if (usdIndex > -1) {
        const [usd] = currencyOptions.splice(usdIndex, 1);
        currencyOptions.unshift(usd);
      }
    }
  }, [currenciesQueried.data, tenantData?.setup?.currency]);

  const onSubmit = async (data: FieldValues) => {
    const { __typename, ...tenant } = data;
    if (isAddForm) {
      const { pim_id, ...stp } = tenant.setup;
      if (pim_id)
        tenant.setup.industry_name = industryTemplates.find(itp => itp.value === pim_id)?.label;
      else
        tenant.setup = stp;
    }
    const res = await upsertMutation({ tenant });
    const alertAction = isAddForm ? MutationAction.Create : MutationAction.Update;
    alertsRef.current?.generate(mutationInfo("the operator", alertAction, res));

    if (!res?.error) {
      appRef.current?.updateStatus(false);
      if (isAddForm) {
        //back to list page
        setTimeout(() => navigate(baseRoute), 300);
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="tenant-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}>
        <Card>
          <div className="card-body">
            <div className="my-3">
              <h4 className="text-uppercase mt-3 mb-0">Tenant Information</h4>
            </div>
            <FormInput
              name="legal_name"
              label="Tenant Legal Name"
              reg_options={{
                required: true,
              }}
            />
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormInput
                  name="name"
                  label="Tenant Name"
                  reg_options={{
                    required: true,
                    pattern: {
                      value: /^[a-z][a-z0-9_]+$/,
                      message:
                        "Name starts with letter and includes lower case letter, number and underscore only",
                    }
                  }}
                  disabled={!isAddForm}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <FormInput
                  name="tenant_url_tag"
                  label="Tenant URL Tag"
                  reg_options={{
                    required: true,
                    pattern: {
                      value: /^[a-z][a-z0-9_]+$/,
                      message:
                        "Tenant URL Tag starts with letter and includes lower case letter, number and underscore only",
                    }
                  }}
                  disabled={!isAddForm}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormSelect
                  name="setup.profit_model"
                  label="Profit Model"
                  options={profitModels}
                  disabled={!isAddForm}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <FormSelect
                  name="setup.pim_id"
                  label="Industry Template"
                  options={industryTemplates}
                  disabled={!isAddForm}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormSelect
                  name="setup.currency"
                  label="Currency"
                  options={currencies}
                  disabled={!isAddForm}
                  reg_options={{ required: true }}
                />
              </div>
              <div className="col-sm-12 col-md-6 pt-4">
                <FormInput
                  type="checkbox"
                  name="setup.bulk_offer"
                  label="Offer Import/SFTP"
                  className="form-check-input"
                  isswitch={true}
                  disabled={!isAddForm}
                />
              </div>
            </div>
            <FormSelect
              name="environment_type"
              label="Environment Type"
              options={environmentTypes}
            />
            <div className="border-top my-3">
              <h4 className="text-uppercase mt-3 mb-0">contact details</h4>
            </div>
            <Contact ignores={["alter_phone"]} parentPrefix="contact." />
            <Address parentPrefix="address." />
            <FormInput
              name="website"
              label="Website"
              type="url"
              placeholder="http://yourwebsite.com"
              reg_options={{ maxLength: 65 }}
            />
          </div>
        </Card>
        <div className="mt-4 d-flex justify-content-end">
          <Button
            data-testid=""
            disabled={!formChanged}
            type="submit"
            className="btn btn-primary"
            id="tenant-save-btn">
            Save
          </Button>
        </div>
      </Form>
    </FormProvider >
  )
}

export default TenantForm