import { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Vw_Sales_By_Order_Order_By, useSalesByOrderQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Table } from "../../common/components/Table/index";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IDateRange, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { ColumnAlignLeft } from "./ColumnAlignEnd";
import { ZeroedOutMoney } from "./ZeroedOutMoney";
interface IProp {
  handleCallBack: (where: string,
    orderBy: Vw_Sales_By_Order_Order_By | Vw_Sales_By_Order_Order_By[]) => void;
};

export const SalesByStoreDateTable: FC<IProp> = (props) => {
  const [context] = useMainContext();
  const [loading, setLoading] = useState<number>(-1);
  const { handleCallBack } = props;
  const formatDate = useDateFormat();
  const tenant_profit_model = context.operatorInfo.setup.profit_model;
  const { number_items_per_page } = context.operatorSettings.preset;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const userInfo = useUserInfo();
  const accessOrder = userInfo.permissions! & context.permissions.access_order;
  let tableData: { id: string }[] | undefined = [];
  const [offset, setOffset] = useState<number>(0);
  let currentDate = new Date();

  let last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  let initNewRangeDate: IDateRange = {
    startDate: new Date(last7Days.getFullYear(), last7Days.getMonth(), last7Days.getDate()),
    endDay: new Date(),
    column_name: "created_date",
  };
  const [dateRange, setdateRange] = useState<IDateRange>(initNewRangeDate);
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "",
    column_name: "",
    orderBy: "",
  });
  const orderByOptions: { [key: string]: string } = {
    Date: "created_date",
    Order: "order_number",
    Status: "trg_status",
    Status_History_Date: "status_history_date",
    Product_Sale_Amount: "trg_sub_total",
    [tenant_profit_model]: "trg_commission",
    Taxes: "tax_amount",
    Shipping: "shipping_price",
    Gross_Sales: "gross_sale",
    Net_Sales: "net_sale",
  };
  const tableColumnsToSearch: string[] = ["order_number_text"];

  let tableColumns = [
    "Date",
    "Order",
    "Status",
    "Status History Date",
    "Product Sale Amount",
    "Markup",
    "Taxes",
    "Shipping",
    "Gross Sales",
    "Net Sales",
  ];
  const footerColumns = [
    { key: 'trg_sub_total', label: 'Product Sale Amount' },
    { key: 'trg_commission', label: tenant_profit_model },
    { key: 'tax_amount', label: 'Taxes' },
    { key: 'shipping_price', label: 'Shipping' },
    { key: 'gross_sale', label: 'Gross Sales' },
    { key: 'net_sale', label: 'Net Sales' }
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : [{ [orderByOptions.Date]: "desc" }];

  const customRule = `,"operator_id": {"_eq":"${userInfo.operator_id}"}`;
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    dateRange,
    selectedFilters,
    customRule
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useSalesByOrderQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "SalesByStoreDateTable", errMsg: error.message }} />;
  }


  const rows = queried.data?.vw_sales_by_order;
  const totalRows = queried.data?.items?.aggregate?.count ?? 0;
  const getsubdata: { label: string; value: string }[] = Object.entries(queried.data?.items?.aggregate?.sum ?? {}).map(([key, value]) => ({ label: key, value: value != null ? value.toString() : '0' }));
  const subTotal = getsubdata
    .filter(item =>
      footerColumns.some(column => column.key === item.label)
    )
    .map(item => {
      const matchingColumn = footerColumns.find(column => column.key === item.label);
      return {
        ...item,
        label: matchingColumn ? matchingColumn.label : item.label
      };
    });

  handleCallBack(handleWhereStr, orderByString);
  if (rows) {
    tableData = rows?.map((row) => {
      return {
        id:
          row.id ?? "MISSING ID",
        Date: (
          <div className="date-created">
            <p className="mb-0">{formatDate(row.created_date)}</p>
          </div>
        ),
        Order: accessOrder ? (
          <NavLink
            data-role="nav"
            data-testid="nav-message"
            // TODO: alter SQL to make id unique and replace with store id
            to={`/${tenantUrlTag}/orders/${row.id}`}
            style={{ maxWidth: "180px" }}
            className="nav-link text-truncate text-decoration-underline"
          >
            {row.order_number}
          </NavLink>
        ) : (
          <ColumnAlignLeft className="order">
            <p className="mb-0">{row.order_number}</p>
          </ColumnAlignLeft>
        ),
        Status: (
          <ColumnAlignLeft className="status">
            <p className="mb-0">{row.trg_status}</p>
          </ColumnAlignLeft>
        ),
        Status_History_Date: (
          <ColumnAlignLeft className="status-history-date">
            <p className="mb-0">{formatDate(row.status_history_date)}</p>
          </ColumnAlignLeft>
        ),
        Product_Sale_Amount: (
          <ColumnAlignLeft className="product-sale-amount">
            <ZeroedOutMoney amount={(row.trg_sub_total ?? 0)} />
          </ColumnAlignLeft>
        ),
        [tenant_profit_model]: (
          <ColumnAlignLeft className={tenant_profit_model}>
            <ZeroedOutMoney amount={row.trg_commission ?? 0} />
          </ColumnAlignLeft>

        ),
        Taxes: (
          <ColumnAlignLeft className="taxes">
            <ZeroedOutMoney amount={Number(row.tax_amount) ?? 0} />
          </ColumnAlignLeft>
        ),
        Shipping: (
          <ColumnAlignLeft className="shipping">
            <ZeroedOutMoney amount={row.shipping_price ?? 0} />
          </ColumnAlignLeft>
        ),
        Gross_Sales: (
          <ColumnAlignLeft className="gross-sales">
            <ZeroedOutMoney amount={row.gross_sale} />
          </ColumnAlignLeft>
        ),
        Net_Sales: (
          <ColumnAlignLeft className="net-sales">
            <ZeroedOutMoney amount={row.net_sale} />
          </ColumnAlignLeft>
        ),

      };
    });
  }

  const applyChanges = () => { };
  const addFilterOptions = [
    {
      label: "Status", value: "trg_status",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Pending', value: 'Pending' },
          { label: 'Awaiting Fulfillment', value: 'AwaitingFulfillment' },
          { label: 'Shipped', value: 'Shipped' },
        ]
    },
    { label: "Product Sale Amount", value: "trg_sub_total", type: "numeric" },
    { label: "Gross Sales", value: "gross_sale", type: "numeric" },
    { label: "Net Sales", value: "net_sale", type: "numeric" },
    { label: "Shipping", value: "shipping_price", type: "numeric" },
    { label: "Taxes", value: "tax_amount", type: "numeric" },
  ];

  return (
    <Card>
      <Table
        setSearchValue={setSearchValue}
        columnNames={tableColumns}
        data={tableData}
        applyChanges={applyChanges}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalRows}
        ref={multiSelectRef}
        multiSelectTable={false}
        dateRange={dateRange}
        predefinedDateRange={true}
        filters
        setDateRange={setdateRange}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Order #"
        searchHint="Search by Order #"
        subTotal={subTotal}
      />
    </Card>
  );
};
