import { Route, Routes } from "react-router-dom"
import { Error403 } from "../../common/components/Errors/Error403"
import { useUserInfo } from "../../common/hooks/useUserInfo"
import { useMainContext } from "../../layout/components/MainProvider"
import PIMHistoryList from "./PIMHistoryList"

const PIMHistoryRoute = () => {
  const [context] = useMainContext()
  const userInfo = useUserInfo()
  const viewPIMHistory = userInfo.user_level! > 20 && context.operatorInfo.setup.is_pim

  if (!viewPIMHistory) {
    return <Error403 />
  }
  return (
    <Routes>
      <Route index element={<PIMHistoryList />} />
    </Routes>
  )
}

export default PIMHistoryRoute