import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Imports_Seller_Offer_Imports_Insert_Input, useInsertSellerOfferImportMutation
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { OFFER_TEMPLATE_URL, uploadImportFile } from "../../common/miscellaneous/storage";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
export interface IOfferFileUPloadProps {
  setClose: () => void;
}

interface IFileInfo {
  file_name: string;
  file_path: string;
  file_size: number;
}


export const OfferFileUploadComponent: FC<IOfferFileUPloadProps> = ({ setClose }) => {
  const userInfo = useUserInfo()!;
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const navigate = useNavigate();
  const historyUrl = `/${tenantUrlTag}/offers/import-history`;

  const [fileSelected, setFileSelected] = useState<IFileInfo | null>(null);
  const [loader, setLoader] = useState<boolean>(false);


  const [btnImportEnabled, setBtnImportEnabled] = useState<boolean>(false);
  const handleUploadClick = () => {
    uploadRef.current?.click();
  };


  const allowedFileFormats = "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const maxFileSizeKB = context.operatorSettings.preset.document_size ?? 20048; // fix this to correct max file size
  const maxFileSizeMB = Math.round(maxFileSizeKB / 1024);
  const maxFileSizeBytes = maxFileSizeKB * 1024;

  const insertImport = useInsertSellerOfferImportMutation()[1];

  const onFileBlobUpload = async (file: File) => { return await uploadImportFile(file); }

  const setupFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selected = event.target.files[0];

      const typeAllowed = allowedFileFormats.indexOf(selected.type) > -1;
      if (!typeAllowed) {
        alertsRef.current?.add("This file type is not allowed", "error");
        if (uploadRef.current !== null) uploadRef.current.value = "";
        return;
      } else if (selected.size > maxFileSizeBytes) {
        alertsRef.current?.add(`Maximum allowed file size is ${maxFileSizeMB}MB`, "error");
        if (uploadRef.current !== null) uploadRef.current.value = "";
        return;
      } else {
        const uploadedFile: IFileInfo = {
          file_name: selected.name,
          file_path: "",
          file_size: selected.size,
        };
        setFileSelected(uploadedFile);
        setBtnImportEnabled(true);
      }
    }
  };


  const postImport = async (imp: Imports_Seller_Offer_Imports_Insert_Input) => {
    const res = await insertImport({ data: imp });

    if (res && res.error) {
      alertsRef.current?.generate(mutationInfo("offer import", MutationAction.Create, res));
    }
    setLoader(false);
    if (uploadRef.current !== null) uploadRef.current.value = "";
    setBtnImportEnabled(false);
    setFileSelected(null);
  };

  const removeSelectedFile = () => {
    setFileSelected(null);
    if (uploadRef.current !== null) uploadRef.current.value = "";
  };

  const continueImport = async (isContinue: boolean) => {
    if (!isContinue)
      setClose();
    else {
      let imp: Imports_Seller_Offer_Imports_Insert_Input = {
        seller_id: userInfo.seller_id,
        status: "Pending",
        filename_original: null,
        filepath_storage: null,
      };

      if (uploadRef.current?.files && uploadRef.current.files.length > 0) {
        setLoader(true);
        const selected = uploadRef.current.files[0];
        const uploadResult = onFileBlobUpload(selected);
        uploadResult.then((url) => {
          const parts = url.split('/');
          imp.filename_original = selected.name;
          imp.filepath_storage = url;
          imp.file_size = selected.size;
          imp.filename_storage = parts[parts.length - 1];
          imp.created_by_user_id = userInfo.user_id;
          postImport(imp);
          setTimeout(() => navigate(historyUrl), 300);
          setClose();
        });

        uploadResult.catch((err) => {
          alertsRef.current?.add(`File upload failed: ${err}`, "error");
          removeSelectedFile();
          setLoader(false);
        });
      }
    }

  };

  return (
    <Dialog
      title="Import Offers"
      continue={continueImport}
      disableAction={!btnImportEnabled}
    >
      <div className="p-4">
        <div className="col-12 mb-4">
          <a data-role="nav" href={OFFER_TEMPLATE_URL} target="_blank" rel="noreferrer">Download a sample template</a> to see an example of the required format and additional instructions.
        </div>
        <div className="img-dropzone" data-testid="file-upload-zone">
          <div className="box" data-testid="">
            <div className="d-flex flex-column">
              <div className="justify-content-center my-2">
                <div className="col-12">
                  <Button
                    data-testid=""
                    role="button"
                    className="btn btn-primary"
                    type="button"
                    title="Attach file"
                    onClick={handleUploadClick}
                  >
                    Add File
                  </Button>
                </div>
                {fileSelected && (
                  <div className="d-flex font-weight-light align-items-center justify-content-center px-3 py-1 mt-2">
                    <div className="file-name-wrapper me-3">
                      <p className="small file-name font-weight-semi-bold mb-0">
                        {fileSelected?.file_name}
                        <span className="small text-muted ms-2 font-weight-light">
                          {Math.round(fileSelected?.file_size / 1000)} KB
                        </span>
                      </p>
                    </div>
                    <Button
                      data-testid=""
                      className="btn btn-sm btn-link"
                      onClick={removeSelectedFile}
                    >
                      <i className="bi bi-x text-danger display-3"></i>
                    </Button>
                  </div>
                )}
                <Input
                  id="file"
                  type="file"
                  ref={uploadRef}
                  style={{ display: "none" }}
                  data-testid="message-file-upload"
                  accept={allowedFileFormats}
                  onChange={setupFileUpload}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </Dialog>
  );
}