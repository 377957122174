import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Vw_Offer_Order_By, useOfferListCountQuery } from "../../../generated/urql-graphql";
import { ExportData } from "../../azure/components/AzureBlob";
import { PageError } from "../../common/components/Errors";
import { ITab, LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { ActiveOffers } from "./ActiveOffers";
import { AdvancedSettings } from "./AdvancedSettings";
import { ArchivedOffers } from "./ArchivedOffers";
import { ImportHistory } from "./ImportHistory";
import { OfferFileUploadComponent } from "./OfferFileUploadComponent";
export function OfferList() {
  const [context] = useMainContext();

  const { pathname } = useLocation();
  const userInfo = useUserInfo();
  const [importOffer, setImportOffer] = useState<boolean>(false);
  const { sellers_can_define_decrement } = context.operatorSettings.offer;
  const {tenant_url_tag: tenantUrlTag, setup} = context.operatorInfo;
  const notificationUrl = `/${tenantUrlTag}/users/notification`;

  const baseRoute = `/${tenantUrlTag}/offers`;

  const manageOffer = userInfo.permissions! & context.permissions.manage_offer;
  let initInput = {
    variables: {
      order_by: '',
      where: JSON.parse('{}'),
    },
  };
  const [input, setInput] = useState<{}>(initInput);
  let input2 = {};
  const getInputsByStatus = (_status: string) => {
    return {
      variables: {
        operatorId: context.operatorInfo.id,
        "status": _status, "search": "", "and": "{}",
        sellerId: userInfo.seller_id ? userInfo.seller_id : null
      }
    }
  };

  const [allSub] = useOfferListCountQuery(getInputsByStatus("Active,Inactive,OutOfStock"));
  const [archivedSub] = useOfferListCountQuery(getInputsByStatus("Archived"));

  const error = allSub.error || archivedSub.error;
  if (error) {
    return <PageError error={{ source: "OfferList", errMsg: error.message }} />;
  }

  if (!allSub.data || !archivedSub.data) {
    return <Loader />;
  }

  const HandleCallBack = (
    where: string,
    orderBy: Vw_Offer_Order_By | Vw_Offer_Order_By[]
  ) => {
    input2 = {
      variables: {
        order_by: orderBy,
        where: JSON.parse(where),
      },
    };
    if (JSON.stringify(input2) !== JSON.stringify(input)) {
      setInput(input2);
    }
  };
  const all = allSub.data?.search_offers_total_count[0].total_count || 0;
  const archives = archivedSub.data?.search_offers_total_count[0].total_count || 0;
  const tabs: ITab[] = [
    {
      tabName: "All Offers",
      route: `${baseRoute}`,
      totalNumberOfElements: all,
      component: <ActiveOffers numOfOffers={all} sellerId={userInfo.seller_id} handleCallBack={HandleCallBack} />,
    },
    {
      tabName: "Archived Offers",
      route: `${baseRoute}/archived`,
      totalNumberOfElements: archives,
      component: <ArchivedOffers numOfOffers={archives} sellerId={userInfo.seller_id} />,
    }
  ];
  if (manageOffer) {
    tabs.push({
      tabName: "Import History",
      route: `${baseRoute}/import-history`,
      component: <ImportHistory />
    });
  }
  if (sellers_can_define_decrement && manageOffer && userInfo.seller_id) {
    tabs.push({
      tabName: "Advanced Settings",
      route: `${baseRoute}/advanced-settings`,
      component: <AdvancedSettings sellerId={userInfo.seller_id} />
    });
  }
  const setAlert = (message: string, messageType: "success" | "error") => {
    alertsRef.current?.add(message, messageType);
  };

  return (
    <div className="col-12">
      <ReturnHeader title="Offers">
        {manageOffer && userInfo.seller_id && (
          <div className="d-flex flex-xs-column flex-sm-row justify-content-end align-items-start navbar-expand-xl">
            <Button data-testid="toggle-button"
              className="btn btn-light navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target=".navbarProductBtns"
              aria-label="Toggle buttons"
            >
              <i className="bi bi-three-dots-vertical"></i>
            </Button>

            <div className="navbarProductBtns align-items-end navbar-collapse collapse">
              <ul className="navbar-nav ms-xl-auto">
                {(pathname === baseRoute) ?
                  (<>
                    <li className="nav-items">
                      <ExportData queryString={`QUERY_EXPORT_ALL_OFFER`}
                        inputJson={JSON.stringify(input)}
                        caption="Export"
                        setAlert={setAlert}
                        redirectLink={notificationUrl} />
                    </li>
                  </>
                  )
                  : null
                }
                <>
                  {setup.bulk_offer && <li className="nav-items">
                    <Button data-testid="" className="btn btn-light mx-3"
                      onClick={() => setImportOffer(true)}
                    >
                      Import
                    </Button>
                  </li>}
                  <li className="nav-items">
                    <NavLink data-role="nav" data-testid="" to={`${baseRoute}/add`} className="btn btn-primary">
                      Add Offer
                    </NavLink>
                  </li>
                </>
              </ul>
            </div>
          </div>
        )}
      </ReturnHeader>
      <LinkTabsComponent tabs={tabs} />
      {importOffer && <OfferFileUploadComponent setClose={() => setImportOffer(false)} />}
    </div>
  );
}
