import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { useCreateProductMutation, useIndustryProductQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Error403 } from "../../common/components/Errors/Error403";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { IHash } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { getProdImgUrls } from "../types/product";
import { ProductDetails } from "./ProductDetails";
import { ProductImageCarousel } from "./ProductImageCarousel";

export const IndustryProduct: FC = (): ReturnType<FC> => {
  const [context] = useMainContext();
  const { tenant_url_tag: tenantUrlTag, setup } = context.operatorInfo;
  const userInfo = useUserInfo();
  const baseUrl = `/${tenantUrlTag}/products/pim`;
  const formatDate = useDateFormat();
  const [{ fetching: creating }, createMutation] = useCreateProductMutation();
  const navigate = useNavigate();

  const { productId } = useParams<{ productId: string }>();
  const [queried] = useIndustryProductQuery({
    requestPolicy: 'network-only',
    variables: { productId, operatorId: userInfo.operator_id },
  });
  if (!!tenantUrlTag && !setup.pim_id)
    return <Error403 />;

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "IndustryProduct", errMsg: error.message }} />;
  }

  if (!queried.data || queried.fetching) {
    return <Loader />;
  }

  const prodData = queried.data.data_mvw_industry_product?.find(prod => prod)!;
  if (!prodData.tenant_categories.length)
    return <Error403 />;

  const addOffer = async () => {
    let newProdId = prodData.tenant_products.find(prod => prod)?.id;
    if (!newProdId) {
      const newCtgId = prodData.tenant_categories.find(ctg => ctg)?.category_id;
      const product: IHash = {
        data: prodData.data,
        lmfr:prodData.manufacturer_lower,
        lmpn: prodData.mfr_part_number_lower,
        mpin: v4(),
        operator_id: userInfo.operator_id,
        seller_id: null,
        category_id: newCtgId,
        updated_date: 'now()',
        pim_prod_id: prodData.id
      };
      const additionalTypenames = ["data_productship",
        "data_vw_product", "data_vw_product_aggregate"];
      const res = await createMutation({ product }, { additionalTypenames });
      newProdId = res.data?.insert_data_productship_one?.id;
    }
    if (newProdId) {
      navigate(`/${tenantUrlTag}/offers/add/${newProdId}`);
    }
  }

  const productImgUrls = getProdImgUrls(prodData.product_image_urls);
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <ReturnHeader
            url={baseUrl}
            title="PIM Product Detail"
          >
            <div>
              {userInfo.seller_id && prodData &&
                <Button
                  data-testid=""
                  className="btn btn-light me-2"
                  onClick={addOffer}
                >
                  Add Offer
                </Button>}
            </div>
          </ReturnHeader>
          <Card>
            <div className="row p-3">
              <div className="col-sm-12 col-md-3 d-flex justify-content-center">
                {productImgUrls?.length ? (
                  <ProductImageCarousel images={productImgUrls} />
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-none d-md-block img-placeholder">
                      <i className="bi bi-card-image ps-2"></i>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-12 col-md-9">
                <h3 className="sub-title font-weight-semi-bold">
                  {prodData.product_title || "NAME"}
                </h3>
                <div className="mb-0">
                  <span className="font-weight-semi-bold">Manufacturer: </span>
                  {prodData.manufacturer}
                </div>
                <div className="mb-0">
                  <span className="font-weight-semi-bold">Manufacturer Part Number: </span>
                  {prodData.mfr_part_number}
                </div>
                <div className="mb-1">
                  <span className="font-weight-semi-bold">Published: </span>
                  <span className="text-muted">{formatDate(prodData.created_date)}</span>
                </div>
              </div>
            </div>
          </Card>
          <ProductDetails
            categoryId={prodData.category_id}
            productData={prodData}
          />
        </div>
      </div >
    </>
  );
};
