import { NavLink } from "react-router-dom";
import { useGetHubIndustryQuery } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";

const GeneralSettingsView = () => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const { tenant_url_tag: tenantUrlTag, setup } = context.operatorInfo;
  const baseRoute = `/${tenantUrlTag}/settings`;

  const [pimTenantQueried] = useGetHubIndustryQuery()

  const { mng_ctg_attr_view, check_mfr } = context.operatorSettings.product;
  const { member_based_marketpush } = context.operatorSettings.seller
  const mngMfr = check_mfr && (userInfo.permissions! & context.permissions.adv_manage_product)
    && (userInfo.user_level! > 20 || !setup.pim_id);
  const mngCtgAttr = (BigInt(userInfo.permissions)! & BigInt(context.permissions.manage_ctg_n_attr))
    && (mng_ctg_attr_view || userInfo.user_level! > 20);

  const isPimTenant = (pimTenantQueried.data?.pim_hub_industry.length || 0) > 0
  const settingItems = [
    {
      permission: userInfo.permissions! & context.permissions.manage_appearance,
      testid: "Settings-nav-appearance",
      to: `${baseRoute}/appearance`,
      title: "Appearance",
      description: "View, update and customize your marketplace",
      icon: "bi bi-person-video3",
    },
    {
      permission: userInfo.permissions! & context.permissions.manage_app_setting,
      testid: "Settings-nav-application-settings",
      to: `${baseRoute}/app-settings`,
      title: "Application Settings",
      description: "Manage your application settings",
      icon: "bi bi-window",
    },
    {
      permission: userInfo.permissions! & context.permissions.manage_billing_period,
      testid: "Settings-nav-billing-periods",
      to: `${baseRoute}/billing`,
      title: "Billing Periods",
      description: "Manage your billing periods",
      icon: "bi bi-cash-coin",
    },
    //{
    //  permission: userInfo.permissions! & context.permissions.ManageNotification,
    //  testid: "",
    //  to: "/settings/email-settings",
    //  title: "Email Settings",
    //  description: "Manage email settings sent to your sellers and their customers",
    //  icon: "bi bi-send",
    //},
    /*
    {
      permission: userInfo.permissions! & context.permissions.ManageMiscellaneous,
      testid: "",
      to: "/settings/languages",
      title: "Languages",
      description: "Manage languages your sellers can use",
      icon: "bi bi-translate",
    },
    */
    {
      permission: userInfo.permissions! & context.permissions.access_log,
      testid: "",
      to: `${baseRoute}/logs`,
      title: "Logs",
      description: "View log information about events that occurred",
      icon: "bi bi-receipt",
    },
    {
      permission: mngCtgAttr,
      testid: "",
      to: `${baseRoute}/product-attributes`,
      title: "Manage Attributes",
      description: "Manage global and custom field attributes",
      icon: "bi bi-list-check",
    },
    {
      permission: mngCtgAttr,
      testid: "Settings-nav-categories",
      to: `${baseRoute}/categories`,
      title: "Manage Categories",
      description: "Manage categories used to group your products",
      icon: "bi bi-diagram-3",
    },
    {
      permission: mngCtgAttr,
      testid: "",
      to: `${baseRoute}/category-attributes`,
      title: "Manage Category Attributes",
      description: "Manage attributes under each product category",
      icon: "bi bi-list-check",
    },
    {
      permission: userInfo.permissions! & context.permissions.manage_shipping,
      testid: "",
      to: `${baseRoute}/shipping`,
      title: "Shipping",
      description: "Manage how your sellers ship orders",
      icon: "bi bi-truck",
    },
    {
      permission: userInfo.permissions! & context.permissions.manage_terms,
      testid: "Settings-nav-terms-and-conditions",
      to: `${baseRoute}/terms-and-conditions`,
      title: "Terms and Conditions",
      description: "Manage your terms and conditions",
      icon: "bi bi-card-heading",
    },
    {
      permission: mngMfr,
      testid: "Settings-nav-manufacturer-name",
      to: `${baseRoute}/manage-manufacturer-names`,
      title: "Manage Manufacturer Names",
      description: "Manage manufacturer name variations",
      icon: "bi bi-building-gear",
    },
    {
      permission: isPimTenant,
      testid: "manage-pim-domains",
      to: `${baseRoute}/manage-pim-domains`,
      title: "Manage PIM Resource Domains",
      description: "Manage domains allowed in products data links",
      icon: "bi bi-globe"
    },
    {
      permission: member_based_marketpush,
      testid: "Settings-nav-manage-members",
      to: `${baseRoute}/members`,
      title: "Manage Members",
      description: "Manage members",
      icon: "bi bi-person-fill-add",
    },
    {
      permission: userInfo.permissions!,
      testid: "Settings-nav-webhooks",
      to: `${baseRoute}/webhooks`,
      title: "Webhooks",
      description: "View your webhooks and callback history",
      icon: "bi bi-signpost-split",
    }
    // {
    //   permission: userInfo.permissions! & context.permissions.ManageMiscellaneous,
    //   testid: "",
    //   to: "/settings/files",
    //   title: "Files",
    //   description: "Update images, videos, and documents",
    //   icon: "bi bi-paperclip",
    // },
  ];


  if (pimTenantQueried.error) {
    return <PageError error={{ source: "GeneralSettings", errMsg: pimTenantQueried.error.message }} />;
  }

  return (
    <>
      <h1 className="mb-4">General Settings</h1>
      <div className="col-12 no-decoration">
        <div className="d-flex flex-wrap gap-3">
          {pimTenantQueried.fetching ? <Loader /> :
            settingItems.filter((item) => item.permission)
              .map((item, index) => {
                return (
                  <NavLink
                    data-role="nav"
                    key={`${item.testid}-${index}`}
                    data-testid={item.testid}
                    to={item.to}
                    className="general-setting-card-link"
                  >
                    <div className="d-flex align-items-center bg-white p-3 shadow-sm rounded-3 general-setting-card">
                      <div className="mx-3">
                        <i className={`display-2 ${item.icon}`}></i>
                      </div>
                      <div className="text-left ms-2">
                        <p className="mb-1 display-5 card-title">{item.title}</p>
                        <p className="m-0 display-6 text-muted">{item.description}</p>
                      </div>
                    </div>
                  </NavLink>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default GeneralSettingsView;
