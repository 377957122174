import { FC, useEffect, useState } from "react";
import {
  useDeleteShippingZoneMutation,
  useGetRegionsQuery,
  useShippingZonesInitialQuery,
  useShippingZonesSubscription
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { RegionDto } from "../types/types";
import { ShippingZoneForm } from "./ShippingZoneForm";

export const ManageShippingZones: FC = (): ReturnType<FC> => {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings/shipping`;

  const [queried] = useShippingZonesInitialQuery();
  const [subscribed] = useShippingZonesSubscription({});
  const [{ fetching }, deleteMutation] = useDeleteShippingZoneMutation();
  const [regionsQueried] = useGetRegionsQuery();

  const defState = { ids: null, action: "", item: null };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const [usedCountries, setUsedCountries] = useState<string[]>([]);
  const [usedStates, setUsedStates] = useState<string[]>([]);
  const [countries, setcountries] = useState<RegionDto[]>([]);
  const [us_states, setUsStates] = useState<RegionDto[]>([]);

  const tableColumns = ["Shipping Zone", "Name", "Description", "Regions", "Enabled", "Actions"];

  useEffect(() => {
    if (regionsQueried.data?.countries) {
      const newCountries: RegionDto[] = regionsQueried.data.countries.map((country) => ({
        display: country.display,
        code: country.code
      }));

      setcountries(newCountries);
    }

    if (regionsQueried.data?.us_states) {
      const newUsStates: RegionDto[] = regionsQueried.data.us_states.map((us_state) => ({
        display: us_state.display,
        code: us_state.code
      }));

      setUsStates(newUsStates);
    }

  }, [regionsQueried.data]);

  // Extract and store used regions from zoneData
  useEffect(() => {
    if (zoneData?.shipping_zone) {
      const newUsedCountries = zoneData.shipping_zone.flatMap(zone => zone.regions?.countries || []);
      setUsedCountries(newUsedCountries);

      const newUsedStates = zoneData.shipping_zone.flatMap(zone => zone.regions?.states || []);
      setUsedStates(newUsedStates);
    }
  }, [queried.data, subscribed.data]);

  const error = queried.error || subscribed.error || regionsQueried.error;
  if (error) {
    return <PageError error={{ source: "ManageShippingZones", errMsg: error.message }} />;
  }

  const zoneData = subscribed.data ?? queried.data;
  if (!zoneData) return <Loader />;

  const tableData = zoneData.shipping_zone.map((zone) => {
    let actions = [
      {
        actionType: "edit",
        id: zone.id,
        icon: "bi bi-pencil",
        actionFunc: () => setActionState({ ...actionState, action: "Edit", item: zone })
      },
      {
        actionType: "delete",
        id: zone.id,
        icon: "bi bi-trash",
        actionFunc: () => setActionState({ ...actionState, action: "Delete", item: zone }),
      },
    ];

    return {
      id: zone.id,
      Shipping_Zone: zone.zone_name,
      Name: zone.zone_key,
      Description: <div className="text-truncated">{zone.description}</div>,
      Regions: (
        <div className="zone-name-wrapper">
          {zone.regions?.isInternational === true && (
            <span className="badge bg-warning text-dark">International</span>
          )}
          {zone.regions?.isInternational === false && (
            <span className="badge bg-warning text-dark">Local States</span>
          )}

          {zone.regions?.countries?.map((country_code: string) => {
            const country = countries.find(region => region.code === country_code);
            return country ? (
              <span key={country.code} className="badge bg-light-gray text-dark">
                {country.display}
              </span>
            ) : null;
          })}

          {zone.regions?.states?.map((state_code: string) => {
            const state = us_states.find(region => region.code === state_code);
            return state ? (
              <span key={state.code} className="badge bg-light-gray text-dark">
                {state.display}
              </span>
            ) : null;
          })}
        </div>
      ),
      Enabled: zone.status && <span className="bi bi-check2 text-success"></span>,
      Actions: <DropdownItems items={actions} />,
    };
  });

  const continueDelete = async (isContinue: boolean) => {
    if (!isContinue) {
      setActionState(defState);
    } else {
      const res = await deleteMutation({ id: actionState.item.id });
      setActionState(defState);
      alertsRef.current?.generate(mutationInfo("shipping zone", MutationAction.Delete, res));
    }
  };

  return (
    <div className="manage-shipping-zone">
      <ReturnHeader
        title="Manage Shipping Zones"
        url={baseRoute}
        description="A shipping zone is a geographic region where a certain set of delivery
          methods are offered. Customers will get matched to a shipping zone using
          their shipping address and will be presented with the delivery methods available within that
          zone."
      >
        {userInfo.user_level && userInfo.user_level! >= 20 ?
          <Button
            data-testid=""
            className="btn btn-primary"
            onClick={() => setActionState({ ...actionState, action: "Add" })}
          >
            Add Shipping Zone
          </Button> : <></>}
      </ReturnHeader>
      <Card className="p-4">
        <Table
          columnNames={tableColumns}
          data={tableData}
        />
      </Card>
      {["Add", "Edit"].includes(actionState.action) &&
        <ShippingZoneForm
          onClose={() => setActionState(defState)}
          usedCountries={usedCountries}
          usedStates={usedStates}
          zone={actionState.item}
          countries={countries}
          us_states={us_states}
        />
      }
      <Dialog
        title="Delete Shipping Zone"
        show={actionState.action === "Delete"}
        continue={continueDelete}
        continueText={actionState.action}
        contineBtnCss="btn btn-danger"
        fetching={fetching}
      >
        Are you sure you want to delete this shipping zone?
      </Dialog>
    </div>
  );
};
