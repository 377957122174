import { FC, useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  useDeleteManufacturerNameLookupMutation,
  useChangeMfrMutation,
  useManufacturerNameLookupQuery,
  useVerifiedMfrsQuery,
  useMarkAsVerifiedMutation,
  useCreateMfrNvMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import {
  ELoadingType,
  IActionState,
  IHash,
  IRef,
  MutationAction,
  OrderByType,
} from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { FormSelect } from "../../forms/components/FormSelect";
import { Error403 } from "../../common/components/Errors/Error403";

export const ManufacturerView: FC = () => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const { tenant_url_tag: tenantUrlTag, setup } = context.operatorInfo;
  const baseUrl = `/${tenantUrlTag}/settings`;
  const nonPimConsuming = !setup.pim_id;
  const mngMfr = context.operatorSettings.product.check_mfr
    && (userInfo.permissions! & context.permissions.adv_manage_product) 
    && (userInfo.user_level! > 20 || nonPimConsuming);
  
  const defState = {
    ids: null,
    action: "",
    errorMsg: "",
    item: null,
  };
  const [actionState, setActionState] = useState<IActionState>(defState);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",

  });
  const {
    formState: { errors },
    handleSubmit,
    reset, 
    watch,
    setValue,
    setError
  } = methods;

  const tableBulkActions = ["Mark-as-Verified"];
  const { number_items_per_page } = context.operatorSettings.preset;
  const [loading, setLoading] = useState<number>(-1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const columnNames = ["Correct Name", "Name Variation", "Verified", "Product Count", "Actions"];
  const tableColumnsToSearch = ["correct_name", "name_variation"];
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Correct Name",
    column_name: "correct_name",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    Correct_Name: "correct_name",
    Name_Variation: "name_variation",
    Verified: "is_verified",
    Product_Count: "trg_product_count"
  };
  let customRuleStr = `,"operator_id":{"_eq":"${userInfo.operator_id}"}`;
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : [{ [orderByOptions.Verified]: "asc" }, { [orderByOptions.Correct_Name]: "asc" }],
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          selectedFilters,
          customRule: customRuleStr,
        })
      ),
    },
  };
  const [, changeMutation] = useChangeMfrMutation();
  const [, addMutation] = useCreateMfrNvMutation();
  const [, updateMutation] = useMarkAsVerifiedMutation();
  const [{ fetching }, deleteMutation] = useDeleteManufacturerNameLookupMutation();
  const [queried] = useManufacturerNameLookupQuery(inputs);
  const [vmfrQueried] = useVerifiedMfrsQuery({requestPolicy: 'network-only'});
  const multiSelectRef = useRef<IRef | null>(null);
  const addFilterOptions = [
    { label: "Correct Manufacturer Name", value: "correct_name_lower" },
    { label: "Name Variation", value: "name_variation" },
  ];

  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);


  const actionType = watch("action_type");
  const vmfr = watch("correct_name") || "";
  useEffect(()=>{
    if (actionState.action === "add_mfr_nv") {
      reset({ action_type: "mfr", correct_name: "", name_variation: ""});
    }
    else if (actionState.action === "change_mfr") {
      reset({ 
        action_type: "diff", 
        correct_name: actionState.item.correct_name, 
        name_variation: actionState.item.name_variation
      });
      
    }
  }, [actionState.action]);
  useEffect(()=>{
    if (actionType === "mfr" && !!vmfr) {
      setValue("name_variation", vmfr.toLowerCase());
    }
  }, [actionType, vmfr]);

  if (!mngMfr)
    return <Error403 />;

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ManufacturerView", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return (
      <>
        <Card>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={columnNames} numberOfRows={number_items_per_page} />
        </Card>
      </>
    );
  }
  
  const mfrNvs = queried?.data.data_vw_manufacturer_lookup;
  const mfrOptions = vmfrQueried?.data?.data_manufacturer_lookup
    .map(mnv=>({value: mnv.correct_name!, label: mnv.correct_name!})) || [];
  const applyChanges = () => {
    const ids = multiSelectRef.current?.selectedData;
    const action = multiSelectRef.current?.selectedOption;
    if (ids && ids.length !== 0)
      setActionState({ ...actionState, ids, action: action! });
    return true;
  };
  const resetData = () => {
    multiSelectRef.current?.clearSelectedData();
    reset();
    setActionState(defState);
  };
  const deleteItems = async () => {
    const res = await deleteMutation(
      { ids: actionState.ids },
      { additionalTypenames: ["data_manufacturer_lookup", "data_vw_manufacturer_lookup"] });

    alertsRef.current?.generate(mutationInfo("manufacturer name lookup",
      MutationAction.Delete, res));
    if (res.error) {
      return;
    }
    resetData();
  };
  const markAsVerified = async () => {
    const res = await updateMutation(
      { ids: actionState.ids },
      { additionalTypenames: ["data_manufacturer_lookup", "data_vw_manufacturer_lookup"] });

    alertsRef.current?.generate(mutationInfo("manufacturer name lookup",
      MutationAction.Update, res));
    if (res.error) {
      return;
    }
    resetData();
  };

  const continueAction = async (isContinue: boolean) => {
    if (!isContinue) 
      resetData();
    else if (actionState.action === "Delete") {
      await deleteItems();
      resetData();
    }
    else if (actionState.action === "Mark-as-Verified") {
      await markAsVerified();
      resetData();
    }
  };

  const onSubmit = async (formData: FieldValues) => {
    const isChange = actionState.action === "change_mfr";
    let mfrNv : IHash = {
      correct_name: formData.correct_name.trim(),
      name_variation: formData.name_variation.trim().toLowerCase(),
      operator_id: userInfo.operator_id,
    };

    const isSame = mfrNv.correct_name.toLowerCase() === mfrNv.name_variation;
    let res;
    if (isChange) {
      if (formData.action_type === "same" && !isSame) {
        setError("correct_name", { type: "custom", message: "The correct name must match the name variation but may have different case formatting" });
        return;
      }
      res = await changeMutation(
        { ...mfrNv },
        { additionalTypenames: ["data_manufacturer_lookup", "data_vw_manufacturer_lookup"] }
      );
    }
    else { //add 
      mfrNv = {
        ...mfrNv,
        is_verified: true,
        source: "admin",
        trg_product_count: isSame ? 0 : 
          (mfrNvs?.find(mn=>mn.correct_name_lower === mfrNv.correct_name.toLowerCase())?.trg_product_count || 0)
      };
      res = await addMutation(
        { mfrNameLookup: mfrNv },
        { additionalTypenames: ["data_manufacturer_lookup", "data_vw_manufacturer_lookup"] }
      );
    }

    if (!res.error) {
      resetData();
    }
    alertsRef.current?.generate(mutationInfo("data_manufacturer_lookup", isChange ? MutationAction.Update : MutationAction.Add, res));
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    let target = event.currentTarget as HTMLInputElement;
    target.value = target.value.toLowerCase();
  };
  const totalCount = queried.data?.data_vw_manufacturer_lookup_aggregate?.aggregate?.count;
  const tableData = mfrNvs?.map((item) => {
    const actions = [
      {
        enabled: mngMfr && !item.is_verified,
        actionType: "mark-as-verified",
        id: item.id,
        display: "Mark as Verified",
        icon: "bi bi-check-circle",
        actionFunc: () => setActionState({
          ...actionState,
          ids: [item.id],
          action: "Mark-as-Verified",
          item: item
        }),

      },
      {
        enabled: item.trg_product_count<=50000 && mngMfr,
        actionType: "edit",
        id: item.id,
        display: "Change/Verify Manufacturer",
        icon: "bi bi-pencil",
        actionFunc: () => setActionState({
          ...actionState,
          ids: [item.id],
          action: "change_mfr",
          item: item
        }),
      },
      {
          enabled: mngMfr && item.name_variation !== item.correct_name_lower,
          actionType: "delete",
          id: item.id,
          display: "Delete",
          icon: "bi bi-trash",
          actionFunc: () => setActionState({
              ...actionState,
              ids: [item.id],
              action: "Delete"
          }),
 
      }
    ];
    const availActions = actions.filter((action) => action.enabled);
    return {
      id: item.id,
      Correct_Name:
        <div className="d-flex flex-row align-items-center">
          <p className="mb-0">{item.correct_name}</p>
        </div>,
      Name_Variation:
        <div className="d-flex flex-row align-items-center">
          <p className="mb-0">{item.name_variation}</p>
        </div>,
      Verified: item.is_verified ? 'True' : 'False',
      Product_Count: item.trg_product_count,
      Actions: availActions.length ? <DropdownItems items={availActions} /> : null
    };
  });

  const dlgData : IHash = {
    add_mfr_nv: {
      title: "Add Manufacturer or Name Variation",
      description: <p>Add a new verified manufacturer, or add a name variation to an existing manufacturer</p>
    },
    change_mfr: {
      title: "Change/Verify Manufacturer",
      description: <div><b>Changing the manufacturer will update the manufacturer field for all associated products.</b>
      <p className="mt-2">Switch the name variation to a different manufacturer or adjust the capitalization of the manufacturer name</p></div>
    }
  };
  return <div className="col-12">
    <div>
      <ReturnHeader
        title="Manage Manufacturer Names"
        url={baseUrl}
        description="Manage manufacturer name variations."
      >
        <>
          <div className="navbar-expand-xl d-flex flex-column align-items-end">
            <Button
              data-testid=""
              className="btn btn-primary"
              onClick={() => setActionState({...actionState, action: "add_mfr_nv"})}
            >
              Add Manufacturer or Variation
            </Button>
          </div>
        </>
      </ReturnHeader>
      <small className="display-7 text-muted" >* The Product Count is base on Correct Name. 
        Manufacturer can only be changed for products with a count below 50,000. 
        <br />* Manufacture mapping with same correct name and name variation (case insensitive) can not be deleted.
      </small>
    </div>
    <Card>
      <Table
        setSearchValue={setSearchValue}
        columnNames={columnNames}
        data={tableData}
        options={tableBulkActions}
        applyChanges={applyChanges}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalCount}
        ref={multiSelectRef}
        multiSelectTable={true}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        addFilterOptions={addFilterOptions}
        setSelectedFilters={setSelectedFilters}
        filters
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Correct Name..."
        searchHint={`Search by Correct Name, Name Variation`}
      />
    </Card>
    <Dialog
      show={actionState.action === "Delete"}
      title="Delete"
      continueText="Approve"
      continue={continueAction}
      contineBtnCss={"btn-success"}
      size="lg"
      fetching={fetching}
      errorMsg={actionState.errorMsg}
    >
      Delete selected manufacturer(s) name lookup?
    </Dialog>
    <Dialog
      show={actionState.action === "Mark-as-Verified"}
      title="Mark as Verified"
      continueText="Mark as Verified"
      continue={continueAction}
      contineBtnCss={"btn-success"}
      size="lg"
      fetching={fetching}
      errorMsg={actionState.errorMsg}
    >
      Mark selected item(s) as verified?
    </Dialog>
    <Dialog
      title={dlgData[actionState.action]?.title}
      show={["add_mfr_nv", "change_mfr"].includes(actionState.action)}
      subFormId="manufacturer-form"
      continueText={actionState.action === "change_mfr" ? "Change" : "Add"}
      staticModal={true}
      continue={continueAction}
      fetching={fetching}
    >
      {dlgData[actionState.action]?.description}
      <FormProvider {...methods}>
        <Form
          data-testid="manufacturer-form"
          id="manufacturer-form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="form-group">
            <FormInput
              name="action_type"
              label={actionState.action === "add_mfr_nv" 
                ? "Add Manufacturer or Variation" : "Change Type"}
              type="radio"
              disabled={!mfrOptions.length || actionState.action === "change_mfr" 
                && actionState.item.correct_name.toLowerCase() !== actionState.item.name_variation}
              radio_options={[
                {
                  value: "mfr",
                  label: "Add Verified Manufacturer",
                  disabled: actionState.action !== "add_mfr_nv"
                },
                {
                  value: "nv",
                  label: "Add Name Variation",
                  disabled: actionState.action !== "add_mfr_nv"
                },
                {
                  value: "diff",
                  label: "Switch to a different verified manufacturer",
                  disabled: actionState.action !== "change_mfr"
                },
                {
                  value: "same",
                  label: "Adjust the capitalization of the manufacturer name",
                  disabled: actionState.action !== "change_mfr" 
                },
              ]} 
            />
            {(actionType === "mfr" || actionType === "same") ? 
              <FormInput
                name="correct_name"
                label="Correct Name"
                reg_options={{
                  required: true,
                  minLength: 1,
                  maxLength: 80,
                  pattern: /^\S{1}.*$/
                }}
              /> : 
              <FormSelect
                name="correct_name"
                label="Correct Name"
                options={mfrOptions}
                reg_options={{required: true}}
              />}
            <FormInput
              name="name_variation"
              label="Name Variation"
              reg_options={{
                required: true,
                minLength: 1,
                maxLength: 80,
                pattern: /^\S{1}.*$/
              }}
              onKeyUp={handleKeyUp}
              readOnly={actionState.action === "change_mfr" || actionType === "mfr"}
            />
          </div>
        </Form>
      </FormProvider>
    </Dialog>
  </div>
}