import { FC, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CtgAttrPropsPartFragment, useAddProductMfrNvMutation } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { SearchSelect } from "../../common/components/SearchSelect";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { IActionState, IHash, SelectOption } from "../../common/types/types";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { useMainContext } from "../../layout/components/MainProvider";

interface IProps {
  orderedAttrs: CtgAttrPropsPartFragment[];
  editable: boolean;
  isCreating: boolean;
  globalSec: boolean;
}

const cbSet = new Set<string>();
const cbTrueVals = ["true", "t", "yes", "y"];

export const CategoryAttributes: FC<IProps> = (props) => {
  const { orderedAttrs, editable, isCreating, globalSec } = props;
  const [context] = useMainContext();
  const userInfo = useUserInfo();
  const methods = useFormContext()
  const { check_mfr } = context.operatorSettings.product;
  const [, addMutation] = useAddProductMfrNvMutation();
  const defActState = { action: "", errorMsg: "", ids: null }
  const [actState, setActState] = useState<IActionState>(defActState);
  const selectRef = useRef<SelectRef | null>(null);

  useEffect(() => {
    cbSet.forEach(name => {
      const dbVal = methods.getValues(name);
      if (typeof dbVal === 'string' || dbVal instanceof String) {
        const value = cbTrueVals.some(x => x.toLowerCase() === dbVal.toLowerCase());
        methods.setValue(name, value);
      }
    });
  }, []);

  const addValue = async (value: string, callback: (added: SelectOption | null) => void) => {
    const mfrNameLookup = {
      correct_name: value,
      name_variation: value.toLowerCase(),
      operator_id: userInfo.operator_id,
      source: "product_creation",
      seller_id: null
    };
    if (userInfo.seller_id) {
      mfrNameLookup.seller_id = userInfo.seller_id
    }

    const res = await addMutation(
      { mfrNameLookup },
      { additionalTypenames: ["data_manufacturer_lookup"] }
    );

    if (res.data) {
      const mfr = res.data.insert_data_manufacturer_lookup_one?.correct_name || value;
      const added = { label: mfr, value };
      callback(added);
    }
  }

  const pkgnames = [
    "length_of_product",
    "width_of_product",
    "height_of_product",
    "weight_of_product"
  ];
  let attrs: CtgAttrPropsPartFragment[] = [];
  const pkgAttrs: IHash = {};
  const keyAttrs: IHash = {};

  orderedAttrs.filter(item => item.attr_props?.global === globalSec).forEach(item => {
    const attrname = item.attr_props?.name;
    if (pkgnames.includes(attrname)) {
      item.attr_props = {
        ...item.attr_props,
      };
      pkgAttrs[attrname] = item;
    }
    else if (!["product_image_urls", "product_category"].includes(attrname)) {
      let extras: IHash = {};
      if (!isCreating && ["manufacturer", "mfr_part_number"].includes(attrname)) {
        extras = { readOnly: true };
      }
      else if (isCreating && check_mfr && attrname === "manufacturer") {
        extras = {
          readOnly: true,
          toggleSearch: () => setActState({ ...actState, action: "show" }),
          css: "mfr-btn-input mb-3"
        };
      }
      if (Object.keys(extras).length)
        item.attr_props = { ...item.attr_props, extras };

      if (["manufacturer", "mfr_part_number"].includes(attrname))
        keyAttrs[attrname] = item;
      else
        attrs.push(item);
    }
  });
  if (globalSec) {
    attrs = [
      keyAttrs["manufacturer"],
      keyAttrs["mfr_part_number"],
      ...attrs
    ];
  }

  const textInputs = (item: CtgAttrPropsPartFragment) => {
    return ["textarea", "multi-line text"].includes(item.attr_props.ui_component) ? (
      <FormTextarea
        key={item.attr_props.name}
        name={item.attr_props.name}
        label={item.attr_props.display}
        reg_options={{
          required: item.attr_props.required,
          minLength: item.attr_props.min_length,
          maxLength: item.attr_props.max_length,
        }}
        disabled={!editable}
        tool_tip={item.attr_props.tip || ""}
      />
    ) : (
      <FormInput
        key={item.attr_props.name}
        name={item.attr_props.name}
        label={item.attr_props.display}
        reg_options={{
          required: item.attr_props.required,
          minLength: item.attr_props.min_length,
          maxLength: item.attr_props.max_length,
          validate: (value) =>
            !(value && ["NULL", "?", "-"].includes(value.toUpperCase().trim())) || 'Invalid attribute value',
        }}
        disabled={!editable}
        tool_tip={item.attr_props.tip || ""}
        measurelabel={item.attr_props.measure}
        {...item.attr_props?.extras}
      />
    );
  };
  const numberInputs = (item: CtgAttrPropsPartFragment) => {
    return (
      <FormInput
        key={item.attr_props.name}
        type={editable ? "number" : "text"} //Todo: clean up text value for number for length/width/height/weight
        name={item.attr_props.name}
        label={item.attr_props.display}
        reg_options={{
          required: item.attr_props.required,
          min: item.attr_props.min_length,
          max: item.attr_props.max_length,
        }}
        disabled={!editable}
        tool_tip={item.attr_props.tip ? item.attr_props.tip : ""}
        measurelabel={item.attr_props.measure}
      />
    );
  };
  const datetimeInputs = (item: CtgAttrPropsPartFragment) => {
    return (
      <FormInput
        key={item.attr_props.name}
        type={item.attr_props.input_type}
        name={item.attr_props.name}
        label={item.attr_props.display}
        reg_options={{
          required: item.attr_props.required,
        }}
        disabled={!editable}
        tool_tip={item.attr_props.tip ? item.attr_props.tip : ""}
      />
    );
  };
  const booleanInputs = (item: CtgAttrPropsPartFragment) => {
    if (item.attr_props.ui_component === "input_checkbox") {
      cbSet.add(item.attr_props.name);
    }
    return (
      (item.attr_props.ui_component === "input_radio" && (
        <FormInput
          key={item.attr_props.name}
          name={item.attr_props.name}
          label={item.attr_props.display}
          type="radio"
          radio_options={[
            { label: item.attr_props.on_text, value: item.attr_props.on_text },
            { label: item.attr_props.off_text, value: item.attr_props.off_text },
          ]}
          tool_tip={item.attr_props.tip ? item.attr_props.tip : ""}
        />
      )) ||
      (item.attr_props.ui_component === "input_checkbox" && (
        <FormInput
          key={item.attr_props.name}
          type="checkbox"
          name={item.attr_props.name}
          label={item.attr_props.display}
          reg_options={{
            required: item.attr_props.required,
          }}
          disabled={!editable}
          isswitch="true"
          tool_tip={item.attr_props.tip ? item.attr_props.tip : ""}
        />
      )) ||
      (item.attr_props.ui_component === "select" && (
        <FormSelect
          key={item.attr_props.name}
          name={item.attr_props.name}
          label={item.attr_props.display}
          options={[
            { label: item.attr_props.on_text, value: item.attr_props.on_text },
            { label: item.attr_props.off_text, value: item.attr_props.off_text },
          ]}
          reg_options={{
            required: item.attr_props.required,
          }}
          disabled={!editable}
          tool_tip={item.attr_props.tip ? item.attr_props.tip : ""}
        />
      ))
    );
  };
  const optionsInputs = (item: CtgAttrPropsPartFragment) => {
    return (
      <FormSelect
        key={item.attr_props.name}
        name={item.attr_props.name}
        label={item.attr_props.display}
        options={item.attr_props.options?.map((option: string) => ({
          label: option,
          value: option,
        }))}
        reg_options={{
          required: item.attr_props.required || false,
        }}
        disabled={!editable}
        tool_tip={item.attr_props.tip || ""}
      />
    );
  };

  const continueAdd = (isContinue: boolean) => {
    let errorMsg = "";
    if (isContinue) {
      const value = selectRef.current?.value;
      if (value) {
        methods.setValue("manufacturer", value);
      }
      else
        errorMsg = "Manufacture value can't be empty";
    }
    errorMsg ? setActState({ ...actState, errorMsg }) : setActState(defActState);
  }
  return (<>
    {attrs.length ?
      <Card className={`p-4 mb-4 mt-4 ${!globalSec ? "product-attrs-scroll" : ""}`}>
        {attrs.map((item, index) => {
          return (
            <div key={index}>
              {
                (item.attr_props.type === "text" && textInputs(item)) ||
                (item.attr_props.type === "number" && numberInputs(item)) ||
                (item.attr_props.type === "datetime" && datetimeInputs(item)) ||
                (item.attr_props.type === "boolean" && booleanInputs(item)) ||
                (item.attr_props.type === "options" && optionsInputs(item))}
            </div>
          );
        })}
        {Object.keys(pkgAttrs).length ?
          <>
            <h6 className="title font-weight-semi-bold mb-2">Packaging</h6>
            <Card className="card shadow-sm p-3 mb-3">
              {Array.from({ length: pkgnames.length / 2 }, (elem, index) => {
                const pidx = index * 2;
                return (
                  <div className="row" key={`pkgnames_${index}`}>
                    <div className="col">
                      {numberInputs(pkgAttrs[pkgnames[pidx]])}
                    </div>
                    <div className="col">
                      {(pkgnames.length > pidx + 1) && numberInputs(pkgAttrs[pkgnames[pidx + 1]])}
                    </div>
                  </div>
                );
              })}
            </Card>
          </> : null}
        <Dialog
          show={!!actState.action}
          title="Search Manufacturer"
          continueText="Add"
          staticModal={true}
          continue={continueAdd}
        >
          <SearchSelect
            ref={selectRef}
            label="Manufacturer"
            placeholder="Please search and type manufacturer"
            itemType="mfr"
            required={true}
            addValue={addValue}
            errorMsg={actState.errorMsg}
            itemChanged={(item: SelectOption | null) => item && setActState({ ...actState, errorMsg: "" })}
          />
          <div className="mt-3">
            <i className="bi bi-info-circle me-2"></i>
            If the manufacturer name is not found in the list, you can enter the new name and click on the + sign to add it.
          </div>
        </Dialog>
      </Card> : null}
  </>);
};
