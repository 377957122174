import { FC, useEffect, useState } from "react";
import {
  Invite_Bool_Exp,
  useDeleteInviteMutation,
  useInviteListQuery,
  useReinviteMutation,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import RoleSelector from "./RoleSelector";
import { useMainContext } from "../../layout/components/MainProvider";

interface InviteRole {
  inviteId: string;
  roleId: string;
}

interface UserProps {
  whereString: Invite_Bool_Exp;
  logsPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}
export const PendingInvites: FC<UserProps> = (props) => {
  const [context] = useMainContext();
  const { num_of_days_invite_expire: daysExpire } = context.operatorSettings.preset;
  const { logsPerPage } = props;
  const [loading, setLoading] = useState<number>(-1);
  const formatDate = useDateFormat();
  const columnNames = ["expire date", "company", "email", "invite sent by", "role", "action"];
  const searchColumns = ["email", "cp_inviter"];

  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "expire_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");

  const userInfo = useUserInfo()!;
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "",
    column_name: "",
    orderBy: "",
  });
  const orderByOptions: { [key: string]: string } = {
    expire_date: "expire_date",
    company: "cp_company",
    email: "email",
    invite_sent_by: "cp_inviter",
  };
  const inputs = {
    variables: {
      offset,
      limit: logsPerPage,
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(searchColumns, searchValue),
          dateRange,
          selectedFilters,
          customRule: `,"auth_id":{"_is_null":true},"operator_id":{"_eq":"${userInfo.operator_id}"}`,
        })
      ),
      order_by: orderBy.column_name ? { [orderBy.column_name]: orderBy.orderBy } : undefined
    },
  }

  const [invitesQueried] = useInviteListQuery(inputs);

  const [, deleteInviteMutation] = useDeleteInviteMutation();
  const [, reinviteMutation] = useReinviteMutation();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleteInviteId, setDeleteInviteId] = useState<string>("");
  const [inviteRoles, setInviteRoles] = useState<InviteRole[]>([]);

  useEffect(() => {
    if (invitesQueried.data) {
      setLoading(ELoadingType.None);
      const temp = invitesQueried.data.invite.map((invite) => {
        return { inviteId: invite.id, roleId: invite.role_id };
      });
      if (temp) setInviteRoles(temp);
    }
  }, [invitesQueried.data]);

  const handleRoleChange = (roleId: string, id?: string) => {
    setInviteRoles(
      inviteRoles.map((role) => {
        return role.inviteId !== id ? role : { ...role, roleId: roleId };
      })
    );
  };

  const reset = () => {
    setShowDeleteDialog(false);
    setDeleteInviteId("");
  };

  const reinvite = (id: string) => {
    reinviteMutation({
      inviteId: id,
      expire: new Date(Date.now() + daysExpire * 24 * 60 * 60 * 1000),
      inviter: userInfo.user_id,
      roleId: inviteRoles.find((role) => role.inviteId === id)?.roleId,
    })
      .then((res) => {
        if (res.data && !res.error) {
          reset();
        }
        alertsRef.current?.generate(mutationInfo("user invitation", MutationAction.Send, res));
      })
      .catch((error) => {
        alertsRef.current?.generate(mutationInfo("user invitation", MutationAction.Send, error));
      });
  };

  const deleteInvite = (id: string) => {
    setDeleteInviteId(id);
    setShowDeleteDialog(true);
  };

  const fnContinueDelete = async (continueDelete: boolean) => {
    if (continueDelete) {
      const res = await deleteInviteMutation({ inviteId: deleteInviteId });
      if (!res.error)
        reset();
      alertsRef.current?.generate(mutationInfo("user", MutationAction.Delete, res));
    } else setShowDeleteDialog(false);
  };

  const error = invitesQueried.error;
  if (error) {
    return <PageError error={{ source: "PendingInvites", errMsg: error.message }} />;
  }

  if (!invitesQueried.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={columnNames} />
      </Card>
    );
  }
  const totalNumber = invitesQueried.data?.invite_aggregate?.aggregate?.count;
  const invites = invitesQueried.data!;

  const data = invites.invite.map((user) => {
    const actions = [
      {
        actionType: "Resend Invite",
        id: user.id,
        icon: "bi bi-send",
        actionFunc: (id: string) => {
          reinvite(id);
        },
      },
      {
        actionType: "delete",
        id: user.id,
        actionFunc: (id: string) => {
          deleteInvite(id);
        },
      },
    ];

    return {
      id: user.id,
      expire_date: formatDate(user.expire_date),
      company: user.cp_company,
      email: user.email,
      invite_sent_by: `${user.cp_inviter}`,
      role: <RoleSelector curRoleId={user.role_id} id={user.id} onChange={handleRoleChange} removeMpAdmin={true} />,
      action: <DropdownItems items={actions} />,
      actions: null,
    };
  });

  const addFilterOptions = [
    { label: "Email", value: "email" },
    { label: "Inviter", value: "cp_inviter" },
  ];

  return (
    <Card>
      <Table
        columnNames={columnNames}
        data={data}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalNumber || 0}
        setSearchValue={setSearchValue}
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Email,..."
        searchHint="Search by Email, Invite Sent By"
      />
      <Dialog
        show={showDeleteDialog}
        title="Delete invitation?"
        continueText="Delete"
        continue={fnContinueDelete}
        contineBtnCss="btn-danger"
      >
        <div className="pt-2">Are you sure you want to delete this invitation?</div>
      </Dialog>
    </Card>
  );
};
