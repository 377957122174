import { FC, useEffect, useState } from "react";
import { WorkBook, read, utils } from "xlsx";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { Option } from "../../forms/components/Option";
import { Select } from "../../forms/components/Select";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { ISheetData } from "../../products-import-route/utils/types";
import { IHash } from "../types/types";
import { Spinner } from "./Spinner";

interface IProps {
  setSheetData: (sheetData: ISheetData | null) => void;
  stopParsing: boolean;
  sheetData?: ISheetData | null;
}

interface IWb extends ISheetData {
  workbook: WorkBook | null;
}

export const UploadSpreadsheet: FC<IProps> = (props: IProps) => {
  const { setSheetData, sheetData, stopParsing } = props;
  const defWb = { workbook: null, file: null, sheetName: "" };
  const [wb, setWb] = useState<IWb>({ ...defWb, ...sheetData });
  const [isFileParsing, setIsFileParsing] = useState<boolean>(false);
  //const dropZone = useRef<null | HTMLDivElement>(null);

  const [context] = useMainContext();
  const maxFileSizeKB = context.operatorSettings.preset.document_size ?? 20480; // 20 MB in KB
  const maxFileSizeByte = maxFileSizeKB * 1024; // Convert KB to bytes  
  const maxFileSizeMB = Math.round(maxFileSizeKB / 1024); // Convert KB to MB 

  const setAlert = (message: string, messageType: "success" | "error") => {
    alertsRef.current?.add(message, messageType);
  };

  useEffect(() => {
    if (stopParsing)
      setIsFileParsing(false);
  }, [stopParsing]);


  useEffect(() => {
    if (!wb.workbook || !wb.sheetName) return;

    const sheet = wb.workbook.Sheets[wb.sheetName];
    const jsonData = utils.sheet_to_json(sheet, { header: 1 });
    const header = jsonData.shift() as string[];
    const bodyRows = utils.sheet_to_json(sheet, { raw: false }) as IHash[];
    setSheetData({
      rowData: { header, bodyRows },
      file: wb.file,
      sheetName: wb.sheetName
    });
  }, [wb.sheetName]);

  const fileChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {

    setIsFileParsing(true);
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Check if the file size exceeds the size from the settings.
      if (file.size > maxFileSizeByte) {
        setAlert(`File size exceeds ${maxFileSizeMB}MB. Please upload a smaller file.`, "error");
        setIsFileParsing(false);
        return;
      }

      setWb({ ...wb, file });
      if (
        file.type === "text/csv" ||
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        file.arrayBuffer().then((data) => {
          const workbook = read(data, { raw: true });
          const sheetName = workbook.SheetNames[0];
          setWb({ workbook, file, sheetName });
        });
      }
    }
    else
      setIsFileParsing(false);
  };

  /*   useEffect(() => {
      if (dropZone.current) {
        dropZone.current.addEventListener("dragover", handleDragOver);
        dropZone.current.addEventListener("drop", handleDrop);
      }
  
      return () => {
        if (dropZone.current) {
          dropZone.current.removeEventListener("dragover", handleDragOver);
          dropZone.current.removeEventListener("drop", handleDrop);
        }
      };
    }, []); 
  
    const handleDragOver = (eventDragOver: DragEvent) => {
      eventDragOver.preventDefault();
      eventDragOver.stopPropagation();
    };
  
    const handleDrop = (eventDragDrop: DragEvent) => {
      setIsFileParsing(true)
      eventDragDrop.preventDefault();
      eventDragDrop.stopPropagation();
      if (eventDragDrop.dataTransfer) {
        const { files } = eventDragDrop.dataTransfer;
        const selected = files[0];
        handleFile(selected);
        setIsFileParsing(false)
      } else {
        setIsFileParsing(false)
      }
    };*/

  const handleRemoveFile = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setSheetData(null);
    setWb(defWb);
  };

  const sheetNames = wb.workbook?.SheetNames;
  return (
    <>
      <Form data-testid="Import-file-form">
        <div
          className="d-flex flex-column my-4 select-file-box justify-content-center align-items-center"
        >
          {wb.file ? <h5 className="text-muted">{wb.file.name}</h5> : null}
          <Label
            className="text-muted d-flex flex-column justify-content-center align-items-center"
            data-testid=""
            htmlFor="spreadsheet-file-input"
          >
            {wb.file ? (
              isFileParsing ?
                <Spinner size={20} />
                :
                <Button
                  data-testid="remove-file"
                  type="button"
                  className="btn btn-link"
                  onClick={(event) => handleRemoveFile(event)}
                >
                  Remove
                </Button>
            ) : (
              <>
                <span className="btn btn-primary mb-2">Select a file</span>
                <div className="text-muted small">Supported file types: xlsx, csv</div>
              </>
            )}
          </Label>

          <Input
            ref={null}
            data-testid="spreadsheet-file-input"
            name="file-import"
            id="spreadsheet-file-input"
            type="file"
            style={{ display: "none" }}
            accept=".csv,.xlsx,.xls"
            value={""}
            onChange={(eve: React.ChangeEvent<HTMLInputElement>) => fileChanged(eve)}
          />
        </div>
        {(sheetNames?.length || 0) > 1 ? (
          <>
            <h6>Select a work sheet</h6>
            <Select
              ref={null}
              data-testid="seller-product-select"
              className="form-select w-25"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setWb({ ...wb, sheetName: event.target.value })}
            >
              {sheetNames?.map((name, index) => (
                <Option
                  key={index}
                  data-testid="import-sheet-1"
                  value={name}
                  className="py-2"
                >
                  {name}
                </Option>
              ))}
            </Select>
          </>
        ) : null}
        {wb.sheetName ? (
          <p className="mb-2">
            Sheet:<strong className="text-muted">{wb.sheetName}</strong>
          </p>
        ) : null}
      </Form>
    </>
  );
};
