import React, { FC, useRef, useState } from "react";
import {
  useCreateTermsandConditionsMutation,
  useDeleteTermMutation,
  useFetchTermsSubscription,
  useFetchTermsandConditionsSubscription,
  useSetDefaultTermMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadAppData } from "../../common/miscellaneous/storage";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { A } from "../../forms/components/A";
import { Input } from "../../forms/components/Input";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { PaginationOffset } from "./PaginationOffset";

const TermsAndConditionsView: FC = () => {
  const [context] = useMainContext();
  const [, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const logsPerPage = context.operatorSettings.preset.number_items_per_page || 25;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings`;
  const userInfo = useUserInfo();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const defState = {
    ids: null,
    action: "",
    item: null,
  };
  const [actionState, setActionState] = useState<IActionState>(defState);

  const createTermsandConditionsMutation = useCreateTermsandConditionsMutation()[1];
  const [subscribedData] = useFetchTermsSubscription({});
  const [queriedData] = useFetchTermsandConditionsSubscription({
    variables: {
      offset: (currentPage - 1) * logsPerPage,
      limit: logsPerPage,
    },
  });
  const setDefaultData = useSetDefaultTermMutation()[1];
  const [, deleteTerms] = useDeleteTermMutation();

  const totalNumber = subscribedData.data?.terms_and_conditions.length || 0;

  const handleImportPDFClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let selected = event.target.files[0];
      if (selected.type !== "application/pdf") {
        alertsRef.current?.add("Only PDF files are currently supported.", "error");
        if (fileRef.current !== null) fileRef.current.value = "";
        return;
      } else if (selected.size > 2097152) {
        alertsRef.current?.add("Maximum allowed file size is 2MB.", "error");
        if (fileRef.current !== null) fileRef.current.value = "";
        return;
      } else {
        const item =
          subscribedData.data?.terms_and_conditions?.filter(
            (terms) => terms.terms_name === selected.name
          ) ?? [];
        if (item.length > 0) {
          alertsRef.current?.add("Same name file already exsits.", "error");
          if (fileRef.current !== null) fileRef.current.value = "";
          return;
        }
        const uniqueFileName = `terms_${userInfo.operator_id}_${selected.name}`;
        const file_path = await uploadAppData(selected, uniqueFileName);
        const res = await createTermsandConditionsMutation({
          name: selected.name,
          file_path,
          operator_id: userInfo.operator_id
        });
        if (!res?.error) {
          setCurrentPage(1);
          if (fileRef.current !== null) fileRef.current.value = "";
        }
        alertsRef.current?.generate(mutationInfo("the terms and conditions", MutationAction.Create, res));
      }
    } else return;
  };

  if (
    subscribedData.fetching ||
    !subscribedData.data ||
    queriedData.fetching ||
    !queriedData.data
  ) {
    return <Loader />;
  }
  if (subscribedData.error) {
    return <p>{subscribedData.error.message}</p>;
  }

  const continueChange = async (isContinue: boolean) => {
    if (!isContinue) {
      setActionState(defState);
    }
    else {
      let res;
      if (actionState.action === "SetDefault") {
        res = await setDefaultData({ id: actionState.item });
        alertsRef.current?.generate(mutationInfo("terms and conditions default", MutationAction.Update, res));
      }
      else { //delete
        res = await deleteTerms({ id: actionState.item });
        alertsRef.current?.generate(mutationInfo("terms and conditions", MutationAction.Delete, res));
      }
      if (!res?.error) {
        setActionState(defState);
      }
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <ReturnHeader
            url={baseRoute}
            title="Terms and Conditions"
            description="Manage your own terms and conditions here or view a history of previously uploaded
            versions. All users must accept the terms when they first log into MarketPush and each
            time you update the default terms and conditions."
          />
        </div>
        <div className="col-lg-12 col-xl-8 mx-auto">
          <Card className="p-4">
            <div className="d-flex flex-column">
              <span className="btn bg-primary mb-2 position-relative justify-content-center align-items-center">
                <span className="text-white text-center mt-2 position-absolute left-0 right-0">
                  Choose File
                </span>
                <Input
                  id="file"
                  type="file"
                  ref={fileRef}
                  className="form-control file-upload-button mb-2"
                  data-testid="upload-terms"
                  accept=".pdf"
                  onChange={handleImportPDFClick}
                />
              </span>

              <ul className="list-group no-bullets text-muted">
                <li className="small">Files must be less than 2MB.</li>
                <li className="small">Allowed file type: pdf</li>
              </ul>
              <table className="table table-hover align-middle link-decoration mt-3">
                <thead>
                  <tr>
                    <th>Upload Date</th>
                    <th>Filename</th>
                    <th>Default</th>
                    <th className="width-100px text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {queriedData.data && queriedData.data?.terms_and_conditions.length ? (
                    queriedData.data.terms_and_conditions.map((term, index) => {
                      const actions = [
                        {
                          actionType: "download",
                          id: term.id,
                          name: term.terms_name,
                          path: term.file_path ?? "",
                        },
                        {
                          actionType: "set default",
                          id: term.id,
                          disabled: term.if_default ?? false,
                          icon: "bi bi-check-lg",
                          actionFunc: () => {
                            setActionState({ ...actionState, action: "SetDefault", item: term.id })
                          },
                        },
                        {
                          actionType: "delete",
                          id: term.id,
                          disabled: term.if_default ?? false,
                          actionFunc: () => {
                            setActionState({ ...actionState, action: "Delete", item: term.id })
                          },
                        },
                      ];
                      return (
                        <tr key={index}>
                          <td className="py-3">{new Date(term.updated_date).toLocaleString()}</td>
                          <td className="py-3">
                            <A
                              data-testid={`download-action-${term.id}`}
                              download={term.terms_name}
                              href={term.file_path ?? ""}
                              target="_blank"
                            >
                              {term.terms_name}
                            </A>
                          </td>
                          <td className="text-center">
                            {!!term.if_default &&
                              <span >
                                <i className="ms-2 bi bi-check-lg" />
                              </span>}
                          </td>
                          <td className="text-center">
                            <DropdownItems items={actions.filter(action => !action.disabled)} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <th scope="row" colSpan={3} className="text-center">
                        No Records
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {totalNumber <= logsPerPage ? null : (
              <PaginationOffset
                elementsPerPage={logsPerPage}
                totalOfElements={totalNumber}
                paginate={setCurrentPage}
                currentPage={currentPage}
                setOffset={setOffset}
              />
            )}
          </Card>
        </div>
      </div>
      <Dialog
        title="Terms and Conditions"
        show={!!actionState.action}
        continueText={actionState.action === "SetDefault" ? "Continue" : "Delete"}
        continue={continueChange}
        contineBtnCss={actionState.action === "SetDefault" ? "" : "btn btn-danger"}
      >
        {actionState.action === "SetDefault" ?
          <>
            Updating the <b>Default Terms and Conditions</b> will prompt all users to accept
            the updated terms and conditions the next time they log in. Do you want to
            continue?
          </> :
          <>
            Are you sure you want to permanently delete this file?
          </>}
      </Dialog>
    </>
  );
};

export default TermsAndConditionsView;
