import _ from "lodash";
import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  OfferDetailPartsFragment,
  useCreateOfferMutation,
  useGetCurrencyQuery,
  useSellerCurrencyQuery,
  useUpdateOfferMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, currentDate, isoFormatDate, mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
interface IOfferCol {
  name: string;
  type: string;
  updateCol: boolean;
  nullable: boolean;
}

interface IProps {
  locOptions: SelectOption[];
  productId?: string;
  leadingTime?: number;
  offerData?: OfferDetailPartsFragment;
  setFormStatus?: (changed: boolean) => void;
}

export function OfferDetails({ locOptions, productId, leadingTime, offerData, setFormStatus }: IProps): JSX.Element {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenant_profit_model = context.operatorInfo.setup.profit_model;
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  const patternDecimalDigit = new RegExp(`^[0-9]{1,11}(?:\\.[0-9]{1,${max_decimals_in_unit_price}})?$`);
  const patternMessage = `Limit to ${max_decimals_in_unit_price} decimal digits.`;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseUrl = `/${tenantUrlTag}/offers`;
  const navigate = useNavigate();
  const [priceBreaks, setPriceBreaks] = useState<number>(1);
  const [, createMutation] = useCreateOfferMutation();
  const [, updateMutation] = useUpdateOfferMutation();
  const [currencies, setCurrencies] = useState<{ label: string, value: string }[]>([]);
  const [currenciesQueried] = useGetCurrencyQuery();

  const [sellerCurrencyQuery, setSellerCurrencyQuery] = useSellerCurrencyQuery({ variables: { id: userInfo.seller_id } });

  const isEditing = !!offerData;

  const conditionOptions = [
    { label: "New", value: "New" },
    { label: "Used", value: "Used" },
  ];

  const returnOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const offerCols: IOfferCol[] = [
    { name: "start_available_date", type: "date", updateCol: true, nullable: true },
    { name: "end_available_date", type: "date", updateCol: true, nullable: true },
    { name: "max_order_qty", type: "number", updateCol: true, nullable: true },
    { name: "shipping_lead_time", type: "number", updateCol: true, nullable: true },
    { name: "min_qty_alert", type: "number", updateCol: true, nullable: true },
    { name: "description", type: "text", updateCol: true, nullable: false },
    { name: "stock_quantity", type: "number", updateCol: true, nullable: false },
    { name: "shipping_location_id", type: "text", updateCol: true, nullable: false },
    { name: "allow_returns", type: "boolean", updateCol: true, nullable: false },
    { name: "min_order_qty", type: "number", updateCol: true, nullable: false },
    { name: "pricing", type: "jsonb", updateCol: true, nullable: false },
    { name: "updated_date", type: "date", updateCol: true, nullable: false },
    { name: "condition", type: "text", updateCol: false, nullable: false },
    { name: "seller_product_sku", type: "text", updateCol: false, nullable: false },
    { name: "clearance", type: "boolean", updateCol: true, nullable: false },
    { name: "featured", type: "boolean", updateCol: true, nullable: false },
    { name: "free_shipping", type: "boolean", updateCol: true, nullable: false },
    { name: "increment_quantity", type: "number", updateCol: true, nullable: false },
  ];

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    watch,
    reset,
    unregister,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = methods;

  useEffect(() => {
    if (!isEditing)
      setValue("shipping_lead_time", leadingTime);
  }, [isEditing]);

  useEffect(() => {
    if (offerData) {
      const vals = offerData as IHash;
      let formVals: IHash = {};
      offerCols.forEach(col => {
        let val = vals[col.name] || "";
        switch (col.type) {
          case "boolean":
            val = val || false;
            break;
          case "jsonb":
            setPriceBreaks(offerData.pricing.length);
            break;
        }
        formVals[col.name] = val;
      });

      if (offerData.offer_data)
        formVals.date_code = JSON.parse(offerData.offer_data).date_code || "";

      reset(formVals);
    }
  }, [offerData]);

  useEffect(() => {
    checkFormChanged()
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (currenciesQueried.data?.utility_currency) {
      const currencyOptions = currenciesQueried.data.utility_currency.map((item) => ({
        label: item.display,
        value: item.code,
      }));

      setCurrencies(currencyOptions);

      if (!getValues("currency_code")) {
        const sellerCurrency = sellerCurrencyQuery.data?.seller?.currency;
        const defaultCurrency = offerData?.currency_code ?? sellerCurrency ?? "";
        setValue("currency_code", defaultCurrency);
      }
    }
  }, [currenciesQueried.data, offerData?.currency_code, sellerCurrencyQuery.data]);

  const startDate = watch("start_available_date") || currentDate();
  const minQty = watch("min_order_qty") || 1;
  const disableForm = isEditing && offerData.status == "Archived";

  useEffect(() => {
    setValue("pricing.0.quantity_break", minQty);
  }, [minQty]);

  const pricingData = getValues("pricing");

  useEffect(() => {
    if (pricingData)
      checkFormChanged()
  }, [pricingData])

  const checkFormChanged = () => {
    const changed = Object.keys(dirtyFields).length !== 0;
    if (changed) {
      setFormStatus && setFormStatus(true);
      appRef.current?.updateStatus(true);
    } else if (pricingData?.length !== offerData?.pricing.length) {
      setFormStatus && setFormStatus(true);
      appRef.current?.updateStatus(true);
    } else {
      setFormStatus && setFormStatus(false);
      appRef.current?.updateStatus(false);
    }
  }

  const removePriceBreak = (index: number) => {
    unregister(`pricing.${index}.quantity_break`);
    unregister(`pricing.${index}.unit_price`);
    const temp = getValues("pricing");
    temp.splice(index, 1);
    setValue("pricing", temp);
    setPriceBreaks(temp.length);
  };

  const onSubmit = async (fieldValues: FieldValues) => {
    let res;
    if (isEditing) { //update offer
      const updateCols = offerCols.filter(col => col.updateCol);
      let offerObj = _.pick(fieldValues, updateCols.map(col => col.name));
      offerObj["seller_import_id"] = null;
      offerObj["updated_date"] = new Date().toISOString();

      if (fieldValues.date_code?.length) {
        offerObj["offer_data"] = JSON.stringify({ date_code: fieldValues.date_code });
      }
      updateCols.filter(col => col.nullable).forEach(col => {
        if (!offerObj[col.name])
          offerObj[col.name] = null;
      });
      res = await updateMutation(
        { offerId: offerData?.id!, operatorId: userInfo.operator_id, offerObj },
        {
          additionalTypenames: [
            "offer_aggregate",
            "offer",
            "search_counts",
            "vw_offer",
            "search_offers",
          ],
        }
      );
    }
    else { //new offer
      let offer = { ...fieldValues };
      offerCols.filter(col => col.nullable).forEach(col => {
        if (!offer[col.name])
          offer[col.name] = null;
      });
      offer.seller_id = userInfo.seller_id;
      offer.productship_id = productId;
      offer.operator_id = userInfo.operator_id;

      if (fieldValues.date_code?.length) {
        offer.offer_data = JSON.stringify({ date_code: fieldValues.date_code });
      }
      offer.date_code = undefined;

      res = await createMutation(
        { offer },
        {
          additionalTypenames: [
            "offer_aggregate",
            "offer",
            "search_counts",
            "vw_offer",
            "search_offers",
          ],
        }
      );
    }

    alertsRef.current?.generate(mutationInfo("offer", isEditing ? MutationAction.Update : MutationAction.Create, res));
    if (!res.error) {
      appRef.current?.updateStatus(false);
      if (!isEditing) {
        reset();
        setTimeout(() => navigate(baseUrl), 300);
      }
    }
  };

  const offerPricing = () => {
    return (
      <>
        <h5 className="title font-weight-semi-bold mb-1 mt-4">Price &amp; Volume Discount</h5>
        <p className="text-muted small">
          Create incentives to encourage customers to buy in bulk or in larger quantities.
        </p>
        <Card>
          {Array.from({ length: priceBreaks }, (elm, index) => {
            const minQtyBreak = parseInt(getValues(`pricing.${index - 1}.quantity_break`)) + 1;
            return (
              <div className="row" key={`price_break_${index}`}>
                <div className="col">
                  <FormInput
                    name={`pricing.${index}.quantity_break`}
                    type="number"
                    label="Quantity"
                    reg_options={{
                      required: true,
                      min: Math.max((minQtyBreak || 1), minQty) || 1,
                      max: !index ? minQty : 9999999,
                      valueAsNumber: true,
                    }}
                    disabled={disableForm}
                  />
                </div>
                <div className="col">
                  <div className="d-flex">
                    <div className="col">
                      <FormInput
                        name={`pricing.${index}.unit_price`}
                        type="number"
                        label="Unit Price"
                        reg_options={{
                          required: true,
                          pattern: {
                            value: patternDecimalDigit,
                            message: patternMessage
                          }
                        }}
                        disabled={disableForm}
                        inputgrouplabel="$"
                        step={1 / (10 ** max_decimals_in_unit_price)}
                      />
                    </div>
                    {index > 0 && (
                      <div className="col-auto p-0 d-flex flex-column justify-content-end ms-2 mb-3">
                        <Button
                          data-testid=""
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removePriceBreak(index)}
                        >
                          <i className="bi bi-trash" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="d-flex align-items-center justify-content-between mt-3">
            {!disableForm && (
              <Button
                data-testid=""
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => setPriceBreaks(priceBreaks + 1)}
              >
                Add tier
              </Button>
            )}
          </div>
        </Card>
      </>
    );
  };

  const offerMarkupPricing = () => {
    return (
      <>
        <h5 className="title font-weight-semi-bold mb-1 mt-4">Price &amp; Volume Discount</h5>
        <p className="text-muted small">
          Create incentives to encourage customers to buy in bulk or in larger quantities.
        </p>
        <Card>
          {Array.from({ length: priceBreaks }, (elm, index) => {
            const minQtyBreak = parseInt(getValues(`pricing.${index - 1}.quantity_break`)) + 1;
            return (
              <div className="row" key={`price_break_${index}`}>
                <div className="col">
                  <FormInput
                    name={`pricing.${index}.quantity_break`}
                    type="number"
                    label="Quantity"
                    reg_options={{
                      required: true,
                      min: Math.max((minQtyBreak || 1), minQty) || 1,
                      max: !index ? minQty : 9999999,
                      valueAsNumber: true,
                    }}
                    disabled={disableForm}
                  />
                </div>
                <div className="col">
                  <div className="d-flex">
                    <div className="col me-1">
                      <FormInput
                        name={`pricing.${index}.unit_price`}
                        type="number"
                        label="Unit Price"
                        disabled={true}
                        inputgrouplabel="$"
                        step={1 / (10 ** max_decimals_in_unit_price)}
                      />
                    </div>
                    <div className="col me-1">
                      <FormInput
                        name={`pricing.${index}.origin_price`}
                        type="number"
                        label="Base (My Price)"
                        reg_options={{
                          required: true,
                          pattern: {
                            value: patternDecimalDigit,
                            message: patternMessage
                          }
                        }}
                        disabled={disableForm}
                        inputgrouplabel="$"
                        step={1 / (10 ** max_decimals_in_unit_price)}
                      />
                    </div>
                    {index > 0 && (
                      <div className="col-auto p-0 d-flex flex-column justify-content-end ms-1 mb-3">
                        <Button
                          data-testid=""
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removePriceBreak(index)}
                        >
                          <i className="bi bi-trash" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="d-flex align-items-center justify-content-between mt-3">
            {!disableForm && (
              <Button
                data-testid=""
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => setPriceBreaks(priceBreaks + 1)}
              >
                Add tier
              </Button>
            )}
          </div>
        </Card>
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="offer-details-form"
        id="offer-details-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
      >
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex justify-content-between">
            <h5 className="title font-weight-semi-bold my-3">
              General Information
            </h5>
          </div>

          {offerData &&
            <div className="d-flex align-items-center">
              <span className="unmuted-text">status:</span>
              <span className={`ms-2 ${badgeCss(offerData?.status)}`}>{offerData?.status}</span>
            </div>}
        </div>
        <Card>
          <div className="row">
            <FormInput
              type="checkbox"
              ref={null}
              data-testid=""
              id=""
              name="featured"
              label="Featured"
              className="form-check-input"
              isswitch={true}
            />
          </div>
          <div className="row">
            <FormInput
              type="checkbox"
              ref={null}
              data-testid=""
              id=""
              name="clearance"
              label="Clearance"
              className="form-check-input"
              isswitch={true}
            />
          </div>
          <div className="row">
            <FormInput
              type="checkbox"
              ref={null}
              data-testid=""
              id=""
              name="free_shipping"
              label="Free Shipping"
              className="form-check-input"
              isswitch={true}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <FormInput
                name="seller_product_sku"
                label="Seller Item SKU"
                reg_options={{
                  required: true
                }}
                readOnly={isEditing}
              />
            </div>
            <div className="col-12">
              <FormSelect
                name="currency_code"
                label="Currency"
                options={currencies}
                disabled={true}
                reg_options={{ required: true }}
              />
            </div>
            <div className="col-12">
              <FormTextarea
                name="description"
                label="Description"
                css="w-100"
                reg_options={{
                  maxLength: 300,
                }}
                disabled={disableForm}
              />
            </div>
            <div className="col-12">
              <FormInput
                name="date_code"
                label="Date Code"
                required={false}
                disabled={disableForm}
              />
            </div>
          </div>
        </Card>
        <h5 className="title font-weight-semi-bold mb-3 mt-4">Offer Details</h5>
        <Card>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <FormSelect
                name="shipping_location_id"
                label="Location"
                options={locOptions}
                disabled={disableForm}
                reg_options={{ required: true }}
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <FormSelect
                    name="condition"
                    label="Condition"
                    options={conditionOptions}
                    reg_options={{ required: true }}
                    disabled={isEditing}
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  <FormSelect
                    name="allow_returns"
                    label="Allow returns?"
                    options={returnOptions}
                    reg_options={{ required: false }}
                    disabled={disableForm}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-4" />
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <FormInput
                name="stock_quantity"
                type="number"
                label="Stock Quantity"
                reg_options={{
                  required: true,
                  min: 0,
                  max: 9999999,
                  valueAsNumber: true,
                }}
                disabled={disableForm}
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <FormInput
                    name="shipping_lead_time"
                    type="number"
                    label="Lead time to ship"
                    reg_options={{
                      min: 0,
                      max: 90,
                    }}
                    disabled={disableForm}
                    measurelabel="Days"
                    tool_tip="The order processing time from order placement to shipped."
                    tooltip_position="top"
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  <FormInput
                    name="min_qty_alert"
                    type="number"
                    label="Threshold alert"
                    reg_options={{
                      min: 0,
                      max: 999999,
                    }}
                    disabled={disableForm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <FormInput
                name="min_order_qty"
                type="number"
                label="Minimum order quantity"
                reg_options={{
                  required: true,
                  min: 1,
                  valueAsNumber: true,
                }}
                disabled={disableForm}
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <FormInput
                name="max_order_qty"
                type="number"
                label="Maximum order quantity"
                reg_options={{
                  min: 1,
                  max: 9999999,
                }}
                disabled={disableForm}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <FormInput
                name="increment_quantity"
                type="number"
                label="Increment Quantity"
                defaultValue={1}
                reg_options={{
                  required: true,
                  min: 1,
                  valueAsNumber: true,
                }}
                step="1"
                disabled={disableForm}
              />
            </div>
          </div>
        </Card>
        <h5 className="title font-weight-semi-bold mb-1 mt-4">Availability</h5>
        <p className="text-muted small">
          Define when this offer is available for all customers within the Offer Availability
          Period.
        </p>
        <Card>
          <div className="row mb-3">
            <div className="col-md-12 col-lg-6">
              <FormInput
                name="start_available_date"
                type="date"
                label="Start Available Date"
                placeholder="Start Date"
                reg_options={{
                  min:
                    Date.parse(currentDate()) > Date.parse(startDate) ? startDate : currentDate(),
                }}
                disabled={disableForm}
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <FormInput
                name="end_available_date"
                type="date"
                label="End Available Date"
                placeholder="End Date"
                disabled={!startDate || disableForm ? true : false}
                reg_options={{ min: isoFormatDate(startDate) }}
              />
            </div>
          </div>
        </Card>
        {(tenant_profit_model === "Markup" && offerData) ? offerMarkupPricing() : offerPricing()}
      </Form>
    </FormProvider >
  );
}
