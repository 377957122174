import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useChangeEmulationMutation,
  useRemoveTenantMutation,
  useTenantAdminUsersCountQuery,
  useTenantsInfoQuery,
  useUpdateTenantStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

interface UsersState {
  action: string;
  showActionDialog: boolean;
  actionObjId: string;
  tenantUrlTag: string;
  dialogTitle: string;
  continueText: string;
  dialogContent: JSX.Element;
  tenantId: string;
  mutStatus: string;
}

let columnNames = ["created", "Tenant Name", "Environment Type", "sellers", "products", "offers", "orders", "users", "status", "action"];

export const TenantsList: FC = () => {
  const [context] = useMainContext();
  const formatDate = useDateFormat();
  const [loading1, setLoading] = useState<number>(-1);
  const multiSelectRef = useRef<IRef | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const userInfo = useUserInfo()!;
  const emulating = !isNullOrEmpty(userInfo.emulater);

  const { number_items_per_page } = context.operatorSettings.preset;
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });

  const addFilterOptions = [
    { label: "Tenant Name", value: "operator_name_lower" },
    {
      label: "Status",
      value: "operator_status",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Open' },
          { label: 'Suspended', value: 'Suspended' },
          { label: 'Archived', value: 'Archived' },
        ]
    }
  ];

  const navigate = useNavigate();
  const baseRoute = "/tenants";
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Created",
    column_name: "created_date",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    created: "created_date",
    Tenant_Name: "operator_name",
    Environment_Type: "environment_type",
    sellers: "seller_count",
    products: "product_count",
    offers: "offer_count",
    orders: "order_count",
    users: "user_count",
    status: "operator_status"
  };
  const orderByString = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }]
    : undefined

  const customRule = ',"operator_status": { "_nin": "Archived" }'
  const tableColumnsToSearch = ["operator_status", "operator_name"]
  const input = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
    }
  }
  const [adminsData] = useTenantsInfoQuery(input);
  const [adminsCountData] = useTenantAdminUsersCountQuery({
    variables: {
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
    },
  });

  const [, changeEmulationMutation] = useChangeEmulationMutation();
  const [, updateTenantStatusMutation] = useUpdateTenantStatusMutation();
  const [, removeMutation] = useRemoveTenantMutation();

  const defState = {
    action: "",
    showActionDialog: false,
    actionObjId: "",
    tenantUrlTag: "/",
    dialogTitle: "",
    continueText: "",
    dialogContent: <></>,
    tenantId: "",
    mutStatus: ""
  };
  const [usersState, setUsersState] = useState<UsersState>(defState);
  const continueAction = async (isContinue: boolean) => {
    if (isContinue) {
      const action = usersState.action;
      let res;
      let mutText = "the operator status";
      let mutAct = MutationAction.Update;
      switch (action) {
        case "emulate":
          res = await changeEmulationMutation({
            userId: emulating ? userInfo.emulater : userInfo.user_id,
            emulatee: { operator_uship_id: usersState.actionObjId },
          })
          mutText = "as the user";
          mutAct = MutationAction.Login;
          break;
        case "remove_tenant":
          res = await removeMutation({ tenantId: usersState.tenantId }, { additionalTypenames: ["vw_tenant_info"] });
          mutText = "the operator";
          break;
        default:
          const archiveObj = action === "archive" ? {
            archived: {
              at: new Date().toISOString(),
              by: userInfo.user_id,
            }
          } : {};
          res = await updateTenantStatusMutation({
            ...archiveObj,
            tenantId: usersState.tenantId, status: usersState.mutStatus
          },
            { additionalTypenames: ["vw_tenant_info"] })
          break;
      }
      alertsRef.current?.generate(mutationInfo(mutText, mutAct, res));
      if (!res.error) {
        setUsersState(defState);
        if (action === "emulate")
          window.location.href = usersState.tenantUrlTag;
      }
    }
    else setUsersState(defState);
  }

  useEffect(() => {
    if (adminsData.data) {
      setLoading(ELoadingType.None);
    }
  }, [adminsData.data]);

  const error = adminsData.error;
  if (error) {
    return <PageError error={{ source: "TenantList", errMsg: error.message }} />;
  }

  if (!adminsData.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={columnNames} numberOfRows={number_items_per_page} />
      </Card>
    );
  }
  const tenants = adminsData.data?.vw_tenant_info;
  const totalRecords = adminsCountData.data?.total_admins.aggregate?.count;

  const data = tenants.map((tenant) => {
    const actions = [
      {
        enabled: true,
        actionType: "edit",
        id: tenant.operator_id,
        icon: "bi bi-pencil",
        actionFunc: (id: string) => {
          navigate(`${baseRoute}/${id}`)
        }
      },
      {
        enabled: true,
        actionType: "Log In As Tenant",
        id: tenant.info_obj.uship_id,
        icon: "bi bi-box-arrow-in-right",
        actionFunc: (id: string) =>
          setUsersState({
            action: "emulate",
            actionObjId: id,
            tenantUrlTag: tenant.tenant_url_tag ?? "/",
            showActionDialog: true,
            dialogContent: (<p className="my-2">
              Log in as this tenant?
            </p>),
            dialogTitle: "Log In As Tenant",
            continueText: "Continue",
            tenantId: tenant.operator_id,
            mutStatus: ""
          }),
      },
      {
        enabled: false, //tenant.operator_status != "Suspended",
        actionType: "suspend",
        id: tenant.operator_id,
        icon: "bi bi-pause-btn",
        actionFunc: (id: string) => {
          setUsersState({
            action: "suspend",
            actionObjId: id,
            tenantUrlTag: tenant.tenant_url_tag ?? "/",
            showActionDialog: true,
            dialogContent: (<p className="my-2">
              Are you sure you want to suspend this tenant?
            </p>),
            dialogTitle: "Suspend Tenant",
            continueText: "Suspend",
            tenantId: tenant.operator_id,
            mutStatus: "Suspended"
          })
        }
      },
      {
        enabled: false, //true,
        actionType: "archive",
        id: tenant.operator_id,
        icon: "bi bi-archive",
        actionFunc: (id: string) => {
          setUsersState({
            action: "archive",
            actionObjId: id,
            tenantUrlTag: tenant.tenant_url_tag ?? "/",
            showActionDialog: true,
            dialogContent: (<p className="my-2">
              Are you sure you want to archive this tenant?
            </p>),
            dialogTitle: "Archive Tenant",
            continueText: "Archive",
            tenantId: tenant.operator_id,
            mutStatus: "Archived"
          })
        }
      },
      {
        enabled: false, //tenant.operator_status === "Suspended",
        actionType: "resume",
        icon: "bi-shop",
        id: tenant.operator_id,
        actionFunc: (id: string) => {
          setUsersState({
            action: "resume",
            actionObjId: id,
            tenantUrlTag: tenant.tenant_url_tag ?? "/",
            showActionDialog: true,
            dialogContent: (<p className="my-2">
              Are you sure you want to resume this tenant?
            </p>),
            dialogTitle: "Resume Tenant",
            continueText: "Resume",
            tenantId: tenant.operator_id,
            mutStatus: "Active"
          })
        }
      },
      {
        enabled: tenant.user_count === 1,
        actionType: "Remove Tenant",
        id: tenant.operator_id,
        icon: "bi bi-folder-x",
        actionFunc: (id: string) => {
          setUsersState({
            action: "remove_tenant",
            actionObjId: id,
            tenantUrlTag: tenant.tenant_url_tag ?? "/",
            showActionDialog: true,
            dialogContent: (<p className="my-2">
              Are you sure you want to remove the tenant?
            </p>),
            dialogTitle: "Remove Tenant",
            continueText: "Remove",
            tenantId: tenant.operator_id,
            mutStatus: ""
          })
        }
      }];

    return {
      id: tenant.user_id,
      operator_user: tenant.email,
      role: tenant.role_name,
        Tenant_Name: <div>{tenant.operator_name}{tenant.info_obj.is_pim ?
            (<span className="tooltip-custom top ms-2">
                <span className="tooltip-text">PIM Tenant for Industry {tenant.info_obj.industry_name}
                </span>
                <i className="bi bi-database-fill-gear text-muted"></i>
            </span>)
            : 
            (tenant.info_obj.pim_id && <span className="tooltip-custom top ms-2">
                <span className="tooltip-text">PIM Consuming Tenant for Industry {tenant.info_obj.industry_name}
                </span>
                <i className="bi bi-cloud-download-fill text-muted"></i>
            </span>)
           }
        </div>,
      sellers: tenant.seller_count,
      orders: tenant.order_count,
      offers: <div>{tenant.offer_count} 
        {tenant.info_obj.bulk_offer &&
          <small className="ms-2 tooltip-custom right">
            <span className="tooltip-text">Import/sftp enabled
            </span>
            <i className="bi bi-exclamation-circle"></i>
          </small>}
      </div>,
      products: tenant.product_count,
      users: tenant.user_count,
      created: <div className="date-created">
        <p className="mb-0">{formatDate(tenant.created_date)}</p>
        <span className="small text-muted">{formatDate(tenant.created_date, "time")}</span>
      </div>,
      status: <span className={badgeCss(tenant.operator_status)}>
        {tenant.operator_status}
      </span>,
      action: <DropdownItems items={actions.filter(act => act.enabled)} />,
      Environment_Type: tenant.environment_type
    };
  });
  const applyChanges = () => {
    setSelectedValues(multiSelectRef.current?.selectedData || []);
    return null;
  };

  const options = ["Status: Archive"];
  return (
    <>
      <Card>
        <Table
          columnNames={columnNames}
          dateRange={dateRange}
          data={data}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalRecords || 0}
          setSearchValue={setSearchValue}
          filters
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          ref={multiSelectRef}
          options={options}
          applyChanges={applyChanges}
          setDateRange={setDateRange}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          extraFilterOps={["contains"]}
          orderBy={orderBy}
          loading={loading1}
          setLoading={setLoading}
          addFilterOptions={addFilterOptions}
          searchPlaceHolder="Search by Tenant..."
          searchHint="Search by Tenant"
        />
      </Card>
      <Dialog
        show={usersState.showActionDialog}
        title={usersState.dialogTitle}
        continueText={usersState.continueText}
        continue={continueAction}
        contineBtnCss={usersState.action === "emulate" ? "" : "btn-danger"}>
        {usersState.dialogContent}
      </Dialog>
    </>
  );
};

export default TenantsList;
