import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { GeneralAdministration } from "../../administration-route/components/GeneralAdministration";
import { AppSettingsRoute } from "../../app-settings-route/components/AppSettingsRoute";
import { Error404 } from "../../common/components/Errors/Error404";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ConfigurationsRoute } from "../../configurations-route/components/ConfigurationsRoute";
import { DashboardRoute } from "../../dashboard-route/components/DashboardRoute";
import { FinanceRoute } from "../../finance-route/components/FinanceRoute";
import { OffersRoute } from "../../offers-route/components/OffersRoute";
import { OrdersRoute } from "../../orders-route/components/OrdersRoute";
import PIMHistoryRoute from "../../pim-history-route/components/PIMHistoryRoute";
import { ProductsRoute } from "../../products-route/components/ProductsRoute";
import { ActiveSeller } from "../../reports-route/components/ActiveSeller";
import { FulfillmentRate } from "../../reports-route/components/FulfillmentRate";
import { OffersBySeller } from "../../reports-route/components/OffersBySeller";
import { OrdersByMember } from "../../reports-route/components/OrdersByMember";
import { OrdersOverTime } from "../../reports-route/components/OrdersOverTime";
import { ReportsRoute } from "../../reports-route/components/ReportsRoute";
import { SalesByProduct } from "../../reports-route/components/SalesByProduct";
import { SalesBySeller } from "../../reports-route/components/SalesBySeller";
import { SalesOverTime } from "../../reports-route/components/SalesOverTime";
import { RolePermissionDetails } from "../../role-permissions-route/components/RolePermissionDetails";
import { RolePermissionsRoute } from "../../role-permissions-route/components/RolePermissionsRoute";
import SellerMessagesRoute from "../../seller-messages-route/components/SellerMessagesRoute";
import { SellerShipping } from "../../seller-shipping-route/components/SellerShipping";
import { SellersRoute } from "../../sellers-route/components/SellersRoute";
import { SettingsRoute } from "../../settings-route/components/SettingsRoute";
import { SupportRoute } from "../../support-route/components/SupportRoute";
import { UsersRoute } from "../../users-route/components/UsersRoute";
import { useMainContext } from "./MainProvider";
import { TermsConditionsPage } from "./TermsConditionsPage";
export const PagesRoute: FC = () => {
  const userInfo = useUserInfo();
  const [context] = useMainContext()
  const sellerId = userInfo.seller_id
  return (
    <Routes>
      {!sellerId && <Route path="dashboard">
        <Route index element={<DashboardRoute />} />
      </Route>}
      {/* sellers */}
      <Route path="sellers">
        <Route index element={<SellersRoute />} />
        <Route path="*" element={<SellersRoute />} />
      </Route>

      {/* products */}
      <Route path="products">
        <Route index element={<ProductsRoute />} />
        <Route path="*" element={<ProductsRoute />} />
      </Route>

      {/* offers */}
      <Route path="offers" >
        <Route index element={<OffersRoute />} />
        <Route path="*" element={<OffersRoute />} />
      </Route>

      {/* orders */}
      <Route path="orders"  >
        <Route index element={<OrdersRoute />} />
        <Route path="*" element={<OrdersRoute />} />
      </Route>
      {/* seller messages */}
      <Route path="seller-messages">
        <Route index element={<SellerMessagesRoute />} />
        <Route path="*" element={<SellerMessagesRoute />} />
      </Route>

      {/* users */}
      <Route path="users" >
        <Route index element={<UsersRoute />} />
        <Route path="*" element={<UsersRoute />} />
      </Route>

      {/* seller shipping */}
      <Route path="shipping" element={<SellerShipping />} />

      {/* reports */}
      {userInfo.user_level! > 10 && <Route path="reports" >
        <Route index element={<ReportsRoute />} />
        <Route path="sales-over-time" element={<SalesOverTime />} />
        <Route path="sales-by-product" element={<SalesByProduct />} />
        <Route path="sales-by-store" element={<SalesBySeller />} />
        <Route path="fulfillment-rate-by-store" element={<FulfillmentRate />} />
        <Route path="orders-over-time" element={<OrdersOverTime />} />
        <Route path="transactions" element={<FinanceRoute />} />
        <Route path="offers-by-seller" element={<OffersBySeller />} />
        <Route path="orders-by-member" element={<OrdersByMember />} />
        <Route path="active-seller" element={<ActiveSeller />} />
      </Route>
      }

      <Route path="terms" element={<TermsConditionsPage />} />

      {userInfo.user_level! > 30 &&
        <Route path="configurators" element={<ConfigurationsRoute />} />
      }
      {userInfo.user_level! > 10 &&
        <Route path="finance" >
          <Route index element={<FinanceRoute />} />
          <Route path="transactions" element={<FinanceRoute />} />
        </Route>
      }
      <Route path="support" element={<SupportRoute />} />

      {/* settings */}
      {userInfo.user_level! > 10 && <Route path="settings" >
        <Route index element={<SettingsRoute />} />
        <Route path="*" element={<SettingsRoute />} />
      </Route>
      }

      {/* administration */}
      {userInfo.user_level! > 20 && <Route path="administration">
        <Route index element={<GeneralAdministration />} />
        <Route path="app-settings/*" element={<AppSettingsRoute />} />
        <Route path="role-permissions" >
          <Route index element={<RolePermissionsRoute />} />
          {["add-role", ":roleId"].map((path, index) =>
            <Route key={`role_perms_${index}`} path={path} element={<RolePermissionDetails />} />
          )}
        </Route>
      </Route>
      } {
        userInfo.user_level! > 20 && context.operatorInfo.setup.is_pim &&
        <Route path="pim-sync-history" element={<PIMHistoryRoute />} />
      }
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
};
