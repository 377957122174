import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteOfferMutation,
  useOfferDetailsQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Checkbox } from "../../common/components/Checkbox";
import { Dialog } from "../../common/components/Dialog";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { OfferDetails } from "./OfferDetails";
import { ProductInfo } from "./ProductInfo";

export function EditOffer(): JSX.Element {
  const { offerId } = useParams<{ offerId: string }>();
  const [context] = useMainContext();
  const tenant_profit_model = context.operatorInfo.setup.profit_model;
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  const { enable_price_comparison_report } = context.operatorSettings.seller
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/offers`;
  const formatDate = useDateFormat();
  const navigate = useNavigate();
  const [deleteOffer, setDeleteOffer] = useState<boolean>(false);
  const userInfo = useUserInfo();
  const isSeller = !!userInfo.seller_id;
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const canEdit = isSeller && userInfo.permissions! & context.permissions.manage_offer;
  const canDelete = isSeller && userInfo.permissions! & context.permissions.delete_offer;
  const [offerQueried] = useOfferDetailsQuery({
    requestPolicy: 'network-only',
    variables: {
      offerId: offerId,
    },
  });
  const [, deleteMutation] = useDeleteOfferMutation();

  if (offerQueried.error) return <p>{offerQueried.error.message}</p>;
  else if (!offerQueried.data) return <Loader />;

  const offerData = offerQueried.data.vw_offer?.find(vwo => vwo);
  const locOptions = offerQueried?.data?.address.map(addr => ({ value: addr.value, label: addr.label }));
  const disableForm = !!offerData?.archived_by;

  const continueDelete = async (isContinue: boolean) => {
    if (isContinue) {
      const res = await deleteMutation(
        { ids: [offerId] },
        {
          additionalTypenames: [
            "offer_aggregate",
            "offer",
            "search_counts",
            "vw_offer",
            "search_offers",
          ],
        }
      );
      alertsRef.current?.generate(mutationInfo("offer", MutationAction.Delete, res));
      if (!res.error) {
        setTimeout(() => navigate(baseRoute), 300);
      }
    }
    setDeleteOffer(false);
  };

  const buttons = () => {
    return (
      <>
        {disableForm || !canEdit ? null : (
          <>
            <div className="d-flex justify-content-end">
              {canDelete ? (offerData?.order_count === 0) && (
                <div>
                  <Button
                    data-testid="btn-delete-offer"
                    className="btn btn-outline-danger me-3"
                    onClick={() => setDeleteOffer(true)}
                  >
                    Delete Offer
                  </Button>
                </div>
              ) : <></>}
              <div>
                <Button
                  data-testid="btn-save-offer"
                  className="btn btn-primary"
                  disabled={!formChanged}
                  form="offer-details-form"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    );
  };


  const viewOffer = () => {
    return (
      <Card>
        <div className="row my-1">
          <div className="d-flex justify-content-between">
            <h5 className="sub-title">Offer Description</h5>
            <div>
              <Checkbox
                className="form-check-input"
                ref={null}
                data-testid=""
                id="cbFeatured"
                label="Featured"
                checked={offerData?.featured || false}
                disabled={true}
                value="ok"
                isswitch={true}
              ></Checkbox>
              <Checkbox
                className="form-check-input"
                ref={null}
                data-testid=""
                id="cbClearance"
                label="Clearance"
                checked={offerData?.clearance ? true : false}
                disabled={true}
                value="ok"
                isswitch={true}
              ></Checkbox>
              <Checkbox
                className="form-check-input"
                ref={null}
                data-testid=""
                id="cbFreeShipping"
                label="Free Shipping"
                checked={offerData?.free_shipping ? true : false}
                disabled={true}
                value="ok"
                isswitch={true}
              ></Checkbox>
            </div>
          </div>
          <div className="">{offerData?.description}</div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <span className="font-weight-semi-bold">Offer Details</span>
                </td>
              </tr>
              <tr>
                <th scope="row">Seller Item Sku</th>
                <td>{offerData?.seller_product_sku}</td>
              </tr>
              <tr>
                <th scope="row">Ship from Location</th>
                <td className="text-capitalize text-left">
                  {offerQueried?.data?.address
                    .filter((addr) => addr.value === offerData?.shipping_location_id)
                    .map((addr) => addr.label)}
                </td>
              </tr>
              <tr>
                <th scope="row">Availability</th>
                <td>
                  {formatDate(offerData?.start_available_date) +
                    " - " +
                    formatDate(offerData?.end_available_date)}
                </td>
              </tr>
              <tr>
                <th scope="row">Allow Returns?</th>
                <td>{offerData?.allow_returns ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th scope="row">Stock Quantity</th>
                <td>{offerData?.stock_quantity}</td>
              </tr>
              <tr>
                <th scope="row">Lead Time to Ship</th>
                <td>{offerData?.shipping_lead_time}</td>
              </tr>
              <tr>
                <th scope="row">Minimum Order Quantity</th>
                <td>{offerData?.min_order_qty}</td>
              </tr>
              <tr>
                <th scope="row">Maximum Order Quantity</th>
                <td>{offerData?.max_order_qty}</td>
              </tr>
              <tr>
                <th scope="row">Minimum Quantity alert</th>
                <td>{offerData?.min_qty_alert}</td>
              </tr>
              <tr>
                <th scope="row">Price</th>
                <td>
                  {offerData?.pricing.length > 1
                    ? offerData?.pricing.map((tier: any, index: number, array: any[]) => {
                      const nextTier = array[index + 1];
                      const quantity_range = nextTier
                        ? (tier.quantity_break == nextTier.quantity_break - 1)
                          ? `${tier.quantity_break}`
                          : `${tier.quantity_break}-${nextTier.quantity_break - 1}`
                        : `${tier.quantity_break}+`;
                      if (tenant_profit_model === 'Markup') {
                        return (
                          <div key={index}>
                            <span>{quantity_range}</span>:
                            <span> {tier.unit_price.toFixed(max_decimals_in_unit_price)}</span>
                            <span> (Base: {tier.origin_price ? Number(tier.origin_price).toFixed(max_decimals_in_unit_price) : "undefined"})</span>
                          </div>
                        );
                      }
                      else {
                        return <div key={index}>
                          <span>{quantity_range}</span> : <span>{tier.unit_price.toFixed(max_decimals_in_unit_price)}</span>
                        </div>
                      }
                    }) :
                    (tenant_profit_model === 'Markup' ?
                      <div>
                        <p className="mb-0">{offerData?.min_price.toFixed(max_decimals_in_unit_price)
                          + (offerData?.min_price === offerData?.max_price ?
                            "" : " ~ " + offerData?.max_price.toFixed(max_decimals_in_unit_price)) + " "}
                          (Base: {offerData?.min_origin_price?.toFixed(max_decimals_in_unit_price)
                            + (offerData?.min_origin_price === offerData?.max_origin_price ?
                              "" : " ~ " + offerData?.max_origin_price?.toFixed(max_decimals_in_unit_price))})
                        </p>
                      </div> :
                      offerData?.min_price.toFixed(max_decimals_in_unit_price)
                      + (offerData?.min_price === offerData?.max_price ?
                        "" : " ~ " + offerData?.max_price.toFixed(max_decimals_in_unit_price))
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card >
    );
  };

  return (
    <div className="offers-wrapper">
      <div className="d-flex justify-content-center row">
        <div className="col-sm-12 col-xl-8">
          <ReturnHeader title={`Offer for ${offerData?.seller_product_sku}`} url={baseRoute}>
            {buttons()}
          </ReturnHeader>
          <div className="mx-0">
            <ProductInfo offerData={offerData} />
            {enable_price_comparison_report && offerData?.cp_avg_price_all_sellers != 0 
              && <PriceComparisonwithOtherOffers 
                  avgPriceAllSellers={offerData?.cp_avg_price_all_sellers} 
                  currentPrice={Number(offerData?.pricing[0].unit_price)} 
                  />}
            {canEdit ?
              <OfferDetails
                offerData={offerData}
                locOptions={locOptions}
                setFormStatus={(changed: boolean) => setFormChanged(changed)}
              />
              : viewOffer()}
            {buttons()}
          </div>
        </div>
      </div>
      <Dialog
        show={deleteOffer}
        title="Delete Offer"
        continueText="Delete"
        continue={continueDelete}
        contineBtnCss={"btn-danger"}
      >
        <p className="my-2">Are you sure you want to delete this offer?</p>
      </Dialog>
    </div>
  );
}

const PriceComparisonwithOtherOffers = (props: { avgPriceAllSellers: number, currentPrice: number }) => {
  const { avgPriceAllSellers, currentPrice } = props
  const [context] = useMainContext();
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  const differencePercentage = parseFloat(((currentPrice / avgPriceAllSellers) * 100 - 100).toFixed(2))
  return (<div>
    <div className="d-flex text-muted">
      <div className="text-left me-2">
        Your Price: ${currentPrice?.toFixed(max_decimals_in_unit_price)}
      </div>
      <div className="text-left">
        Avg Price: ${avgPriceAllSellers}
      </div>
      <div className="ms-auto">
        %{differencePercentage < 0 ?
          `${Math.abs(differencePercentage)} below average`
          : differencePercentage == 0 ? differencePercentage : `${differencePercentage} above average`}
      </div>
    </div>
    <div className="progress-container py-1 mb-2">
      <div
        className="progress"
        role="progressbar"
        arial-aria-valuenow={differencePercentage}
        arial-aria-valuemin={0}
        ariel-aria-valuemax={200}>
        <div
          className={"progress-bar progress-bar-striped bg-info"}
          style={{ width: `${((differencePercentage || 0) + 100) / 2}%` }}
        ></div>
      </div>
    </div>
  </div>)
}
