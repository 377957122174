import { useEffect, useState } from "react";
import { useOperatorInfoSubscription, useOperatorSettingsSubscription, usePermissionQuery } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid } from "../../common/miscellaneous/utility";
import { IHash, ISettings } from "../../common/types/types";
import { IMainState, MainType } from "../types/types";
import { createGenericContext } from "./createGenericContext";

interface Props {
  children: React.ReactNode;
}

const [useMainContext, MainContextProvider] = createGenericContext<MainType>();
const MainProvider = ({ children }: Props) => {
  const defOpInfo = {
    id: emptyUuid,
    name: "",
    legal_name: "",
    contact: null,
    address: null,
    custom_sytle: null,
    custom_cs: null,
    site_info: null,
    tenant_url_tag: "",
    setup: {},
    website: "",
    status: ""
  };

  const defOpSettings = {
    preset: {},
    product: {},
    order: {},
    offer: {},
    seller: {},
    smtp: {},
    system: {}
  };

  const defMainState: IMainState = {
    operatorInfo: defOpInfo,
    operatorSettings: defOpSettings,
    permissions: {}
  };

  const userInfo = useUserInfo();
  const inputs = { variables: { operatorId: (userInfo?.operator_id || emptyUuid) } };
  const [context, setContext] = useState<IMainState>(defMainState);
  const [infoSubscribed] = useOperatorInfoSubscription(inputs);
  const [settingSubscribed] = useOperatorSettingsSubscription(inputs);
  const [queried] = usePermissionQuery();

  useEffect(() => {
    let temp = { ...context };
    if (infoSubscribed.data)
      temp = { ...temp, operatorInfo: infoSubscribed.data.operator! };
    if (settingSubscribed.data?.settings?.length) {
      const settings: IHash = {};
      settingSubscribed.data?.settings.forEach(setting => settings[setting.category!] = setting.entries);
      temp = { ...temp, operatorSettings: settings as ISettings };
    }
    if (queried.data) {
      const permissions: IHash = {};
      queried.data.permission.forEach(perm => permissions[perm.name] = Math.pow(2, perm.number));
      temp = { ...temp, permissions };
    }
    setContext(temp);
  }, [infoSubscribed.data, settingSubscribed.data, queried.data]);

  const error = settingSubscribed.error || infoSubscribed.error || queried.error;
  if (error) {
    return <PageError error={{ source: "MainProvider", errMsg: error.message }} />;
  }

  if (!settingSubscribed.data || !infoSubscribed.data || !queried.data) {
    return <Loader />;
  }

  return <MainContextProvider value={[context, setContext]}>{children}</MainContextProvider>;
};

export { MainProvider, useMainContext };


