import { SyntheticEvent } from "react";
import { SelectOption } from "../../types/types";
import { Spinner } from "../Spinner";

interface IProps {
  options: SelectOption[];
  handleSelect: (option: SelectOption) => void;
  total?: number;
  accuTotal?: number;
  multiple?: boolean;
  searchFunc?: () => void;
  fetching?: boolean;
  handleSelectAll?: () => void;
  handleSuppress: (event: SyntheticEvent) => void;
}

export const DropdownOptions = ({
  options,
  total,
  accuTotal,
  multiple,
  searchFunc,
  fetching,
  handleSelect,
  handleSelectAll,
  handleSuppress,
}: IProps) => {
  const nTotal = total || 0;
  const nAccuTotal = accuTotal || 0;
  return (
    <div className="dropdown">
      <div
        className="dropdown-options position-absolute bg-white border shadow rounded" onMouseDown={handleSuppress}>
        {options.length > 0 && (
          <div className="border-bottom">
            {options.map((option, index) => (
              <div
                data-value={option.value}
                className={`option ${option.disabled ? "disabled text-muted" : ""}`}
                key={index}
                onMouseDown={() => {
                  if (!option.disabled) {
                    handleSelect(option);
                  }
                }}
              >
                {option.label}
              </div>
            )
            )}
          </div>
        )}
        <div className="text-muted me-2 d-flex justify-content-between assign-items-center">
          {multiple && !!handleSelectAll && options.length > 0 && (
            <button data-role="button" className="btn btn-sm btn-link" onClick={handleSelectAll}>
              Select all
            </button>
          )}
          {searchFunc &&
            ((nTotal > nAccuTotal && (
              <div className="d-flex align-items-center">
                <small className="mx-2">{`Showing ${options.length} out of ${total}`}</small>
                {fetching ? (
                  <div>
                    <Spinner size={20} />
                  </div>
                ) : (
                  <button data-role="button" className="btn btn-sm btn-link"
                    onMouseDown={(event: SyntheticEvent) => {
                      handleSuppress(event);
                      searchFunc();
                    }}
                  >
                    Show more
                  </button>
                )}
              </div>
            )) ||
              (nTotal === 0 && <p className="p-2 mb-0 text-dark">No result found</p>))}
        </div>
      </div>
    </div>
  );
};
