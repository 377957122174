import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useAddSellerStatusMutation,
  useDeletePendingSellerMutation,
  useLiveFulfillmentRateQuery,
  useRemoveSellerStatusMutation,
  useSellerDataQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import { PageError } from "../../common/components/Errors";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { SellerStatus, getActionProp } from "../miscellaneous/types";
import MerchantAccountSettings, { ISellerMerchantAccountData } from "./MerchantAccountSettings";
import { PaymentMethods } from "./PaymentMethods";
import { ResellerBlacklist } from "./ResellerBlacklist";
import SFTPSettingsForm from "./SFTPSettingsForm";
import { SellerActivity } from "./SellerActivity";
import SellerDashboard from "./SellerDashboard";
import { SellerDetails } from "./SellerDetails";
import { UserRoles } from "./UserRoles";

export const SellersData: FC = (): ReturnType<FC> => {
  const { sellerId } = useParams<{ sellerId: string }>();
  const [context] = useMainContext();
  const { seller: sellerSetting } = context.operatorSettings;
  const { setup } = context.operatorInfo;
  const userInfo = useUserInfo()!;
  const isSeller = userInfo.user_level! < 20;

  const editSellerSettings =
    userInfo.permissions & context.permissions.manage_seller ||
      userInfo.permissions & context.permissions.update_seller
      ? true
      : false;

  const [, addStatusMutation] = useAddSellerStatusMutation();
  const [, removeStatusMutation] = useRemoveSellerStatusMutation();
  const [, deleteMutation] = useDeletePendingSellerMutation();
  const formatDate = useDateFormat();

  const defState = {
    action: "",
    ids: [sellerId!],
  };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = userInfo.seller_id ? `/${tenantUrlTag}` : `/${tenantUrlTag}/sellers`;
  const sellerRoute = `/${tenantUrlTag}/sellers/${sellerId}`;

  const [sellerQuery] = useSellerDataQuery({
    variables: { id: sellerId },
  });

  const [sellerFulfillmentRate] = useLiveFulfillmentRateQuery({
    variables: {
      limit: 1,
      offset: 0,
      where: JSON.parse(`{"id": {"_eq": "${sellerId}" }}`),
    },
  });

  const error = sellerQuery.error || sellerFulfillmentRate.error;
  if (error) {
    return <PageError error={{ source: "SellersData", errMsg: error.message }} />;
  }

  if (!sellerQuery.data || !sellerFulfillmentRate.data) {
    return <Loader />;
  }

  const sellerData = sellerQuery.data?.seller;
  const openStatus = sellerSetting.set_seller_open ?? false;
  if (!sellerData) {
    return <p>No data available</p>;
  }

  const {
    company_name,
    created_date,
    addresses,
    contact,
    userships,
    billing_period_id,
    status_obj,
    cp_status,
    merchant_account_configurations,
  } = sellerData;

  const status = cp_status as SellerStatus;
  const paymentBlocked = status_obj?.block_payment ?? false;

  const sellerMerchantAccountData: ISellerMerchantAccountData = {
    id: merchant_account_configurations[0]?.id,
    seller_id: sellerId!,
    merchant_id: merchant_account_configurations[0]?.api_credentials.merchant_id,
    api_username: merchant_account_configurations[0]?.api_credentials.api_username || "",
    api_password: merchant_account_configurations[0]?.api_credentials.api_password || "",
    is_enabled: merchant_account_configurations[0]?.is_enabled,
    is_verified: merchant_account_configurations[0]?.is_verified,
    merchant_provider: merchant_account_configurations[0]?.merchant_provider
  }

  const tabs = [
    {
      enabled: true,
      tabName: "Dashboard",
      route: sellerRoute,
      component: <SellerDashboard sellerData={sellerData} fulFillmentRateData={sellerFulfillmentRate.data} />,
    },
    {
      enabled: true,
      tabName: "Details",
      route: `${sellerRoute}/details`,
      component: <SellerDetails sellerDetail={sellerData} />,
    },
    {
      enabled: true,
      tabName: "Users and permissions",
      route: `${sellerRoute}/permissions`,
      component: <UserRoles sellerId={sellerId!} />,
    },
    {
      enabled: sellerSetting.member_based_marketpush,
      tabName: "Restricted Resellers",
      route: `${sellerRoute}/restricted-resellers`,
      component: <ResellerBlacklist sellerId={sellerId!} />,
    },
    {
      enabled: userInfo.user_level! >= 20,
      tabName: "Seller Activity",
      route: `${sellerRoute}/activity`,
      component: <SellerActivity sellerInfo={{
        sellerId: sellerId!,
        addressId: addresses[0].id,
        contactInfoId: contact?.id,
        billingPeriodId: billing_period_id
      }} />
    },
    {
      enabled: editSellerSettings,
      tabName: "Settings",
      route: `${sellerRoute}/settings`,
      component:
        <Card className="p-4">
          {setup.bulk_offer && 
            <>
              <SFTPSettingsForm sellerId={sellerData.id} />
              <div className="py-2"><hr /></div>
            </>}
          <PaymentMethods sellerId={sellerData.id} paymentMethod={sellerData.payment_method} />
          {
            sellerSetting.enable_merchant_account_integration &&
            <>
              <div className="py-2"><hr /></div>
              <MerchantAccountSettings sellerMerchantAccountData={sellerMerchantAccountData} />
            </>
          }
        </Card>,
    }
  ];

  const continueAction = async (isContinue: boolean) => {
    const action = actionState.action;
    let muAction = MutationAction.Update;
    if (isContinue) {
      let res;
      if (action.indexOf("add_") !== -1) {
        const key = action.replace("add_", "");
        let obj;
        if (key === "suspended") {
          obj = { suspended: true };
        }
        if (key === "block_payment") {
          obj = { block_payment: true };
        } else if (key === "archived") {
          obj = {
            archived: {
              at: new Date().toISOString(),
              by: userInfo.user_id,
            },
          };
        }

        res = await addStatusMutation(
          {
            sellerIds: [sellerId],
            existingKey: key,
            statusObj: obj,
          },
          { additionalTypenames: ["vw_seller", "seller"] }
        );
      } else if (action.indexOf("remove_") !== -1) {
        const key = action.replace("remove_", "");
        res = await removeStatusMutation(
          {
            sellerIds: [sellerId],
            statusKey: key,
          },
          { additionalTypenames: ["vw_seller", "seller"] }
        );
      }
      else if (action === "delete") {
        res = await deleteMutation({
          sellerId,
        });
        muAction = MutationAction.Delete;
      }

      if (res?.data && !res?.error) {
        setActionState(defState);
      }
      alertsRef.current?.generate(mutationInfo("the seller", muAction, res));
    } else {
      setActionState(defState);
    }
  };

  return (
    <>
      <div className="d-flex flex-xs-column flex-md-row justify-content-between return-header">
        <div className="d-flex align-items-start mb-3">
          {userInfo.user_level! >= 20 && (
            <Link data-role="nav" to={baseRoute}>
              <Button data-testid="" className="btn btn-sm bg-light-gray me-3">
                <i className="bi bi-caret-left-fill text-primary"></i>
              </Button>
            </Link>
          )}
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <h2 className="mb-0 me-3 text-truncate seller-name">{company_name}</h2>
              <span className={badgeCss(status)}>{status}</span>
              {paymentBlocked && (
                <span className={`ms-2 ${badgeCss("Rejected")}`}>Payment Blocked</span>
              )}
            </div>
            <div className="d-flex mt-1">
              <small className="me-3 text-muted">
                <span className="font-weight-medium created-date">Created:</span>
                <span className="ms-1">{formatDate(created_date)}</span>
                <span className="font-weight-medium store-id ms-3">Seller ID:</span>
                <span className="ms-1">{sellerData.shop_id}</span>
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex flex-xs-column flex-sm-row justify-content-end align-items-start navbar-expand-xl">
          {!isSeller && (
            <>
              <Button
                data-testid="toggle-button"
                className="btn btn-light navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=".navbarSellerBtns"
                aria-label="Toggle buttons"
              >
                <i className="bi bi-three-dots-vertical"></i>
              </Button>
              {status === SellerStatus.pending ? (
                <div className="navbarSellerBtns align-items-center navbar-collapse collapse">
                  <ul className="navbar-nav ms-xl-auto">
                    <>
                      {userships.length ? (
                        <li className="nav-items">
                          <Button
                            data-testid=""
                            id="seller-open-btn"
                            className="btn btn-light me-2"
                            onClick={() => setActionState({
                              ...actionState,
                              action: paymentBlocked ? "remove_block_payment" : "add_block_payment"
                            })
                            }
                          >
                            Archive
                          </Button>
                        </li>
                      ) : (
                        <li className="nav-items">
                          <Button
                            data-testid=""
                            id="seller-open-btn"
                            className="btn btn-danger me-3"
                            onClick={() => setActionState({ ...actionState, action: "delete" })}
                          >
                            Delete
                          </Button>
                        </li>
                      )}
                    </>
                    {openStatus && (
                      <li className="nav-items">
                        <Button
                          data-testid=""
                          id="seller-open-btn"
                          className="btn btn-primary"
                          onClick={() => setActionState({ ...actionState, action: "remove_pending" })}
                        >
                          Open for Business
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              ) : (
                <div className="navbarSellerBtns align-items-center navbar-collapse collapse">
                  <ul className="navbar-nav ms-xl-auto">
                    <li className="nav-items">
                      <Button
                        data-testid=""
                        className="btn btn-light"
                        onClick={() => setActionState(
                          { ...actionState, action: SellerStatus.archived ? "remove_archived" : "add_archived" }
                        )}
                        id="add-seller-archive-button"
                      >
                        {status === SellerStatus.archived ? "Restore" : "Archive"}
                      </Button>
                    </li>
                    <li className="nav-items">
                      <Button
                        data-testid=""
                        className={`btn ${paymentBlocked ? "btn-outline-success" : "btn-outline-danger"
                          } ms-3`}
                        id="add-seller-block-payment-button"
                        onClick={() => setActionState({
                          ...actionState,
                          action: paymentBlocked ? "remove_block_payment" : "add_block_payment"
                        })}
                      >
                        {paymentBlocked ? "Resume Payment" : "Block Payment"}
                      </Button>
                    </li>

                    {![SellerStatus.pending, SellerStatus.archived].includes(status) && (
                      <li className="nav-items">
                        <Button
                          data-testid=""
                          id="add-seller-resume-suspend-button"
                          disabled={[SellerStatus.archived, SellerStatus.pending].includes(status)}
                          onClick={() => setActionState({
                            ...actionState,
                            action: status === SellerStatus.suspended ? "remove_suspended" : "add_suspended"
                          })}
                          className={`btn ms-3 ${status === SellerStatus.suspended ? "btn-success" : "btn-danger"
                            }`}
                        >
                          {status === SellerStatus.suspended ? "Resume" : "Suspend"}
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="d-flex d-none d-sm-block mt-3">
        <div className="col-12 mb-4">
          <LinkTabsComponent tabs={tabs.filter(tab => tab.enabled)} baseRoute={sellerRoute} />
        </div>
      </div>
      <Dialog
        show={!!actionState.action}
        title={getActionProp(actionState.action, "dlgTitle")}
        continueText={getActionProp(actionState.action, "display")}
        continue={continueAction}
        contineBtnCss={getActionProp(actionState.action, "css")}
      >
        <div className="dlg-msg">{getActionProp(actionState.action, "dlgMsg")}</div>
      </Dialog>
    </>
  );
};